import React, { useEffect, useState } from "react";
import Global from "../../utils/Global";
import User from "../../utils/User";
import Form from "../../utils/Form/Form";
import account_types from "../../config/account_types";
import AccountTypeRadioButtons from "../Inputs/AccountTypeRadioButtons";
import patient_account_types from "../../config/patient_account_types";
import PatchForm from "../../utils/Form/PatchForm";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const ConfirmAccountTypeModal = ({ isOpen, handleClose, handleSave, subjectForm, setSubjectForm, errorText, setErrorText }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Confirm Account Type Change</h5>
            <button className="btn-close" onClick={handleClose} aria-label="Close"></button>
          </div>
          {subjectForm?.account_type.getUI() === patient_account_types.inclinic ? (
            <div className="modal-body">
              <p>By pressing confirm you are removing remote access for this user. They will no longer be able to sign in to their remote user account.</p>
            </div>
          ) : (
            <div className="modal-body">
              <p>Enter patient's phone number for account activation.</p>

              <PhoneInput
                        country="US"
                        countries={["US"]}
                        defaultCountry="US"
                        addInternationalOption={false}
                        limitMaxLength={true}
                        // international={false}
                        // countryCallingCodeEditable={false}
                        placeholder="Enter phone number"
                        value={subjectForm.phone_number.getUI()}
                        onChange={(value) => {
                          setErrorText("")
                          subjectForm.phone_number.handleInputChange(value); // Handling the input as a string
                          setSubjectForm(PatchForm.copy(subjectForm));
                        }}
                        style={{marginBottom: 10}}
                      />
              <div>
                {subjectForm.phone_number.error && <div className="tiny alert alert-danger">{subjectForm.phone_number.error}</div>}
                {!subjectForm.phone_number.error && errorText && <div className="tiny alert alert-danger">{errorText}</div>}
              </div>
              <p>By pressing confirm you are authorizing account setup to be sent to this phone number. Charges may incur.</p>
            </div>
          )}

          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={handleClose}>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={handleSave}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmAccountTypeModal;
