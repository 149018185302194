import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ErrorImage } from "../images/404-error.svg";
import { ArrowLeft } from "react-feather";
export default function Error404() {
  let navigate = useNavigate();

  const onReturnPressed = async (event) => {
    event.preventDefault();
    navigate(-1);
  };

  useEffect(() => {
    document.body.classList.add("bg-white");

    return () => {
      document.body.classList.remove("bg-white");
    };
  }, []);

  return (
    <>
      <div id="layoutError">
        <div id="layoutError_content">
          <main>
            <div className="container-xl px-4">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <div className="text-center mt-4">
                    <ErrorImage />
                    <p className="lead">This requested URL was not found on this server.</p>
                    <div className="text-arrow-icon link-primary" onClick={onReturnPressed}>
                      <ArrowLeft size={15} className="ms-0 me-1" />
                      Return
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <div id="layoutError_footer">
          <footer className="footer-admin mt-auto footer-light">
            <div className="container-xl px-4">
              <div className="row">
                <div className="col-md-6 small">Copyright © StrideLink 2024</div>
                {/* <div className="col-md-6 text-md-end small">
                  <a href="#!">Privacy Policy</a>·<a href="#!">Terms &amp; Conditions</a>
                </div> */}
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}
