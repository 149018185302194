export const tooltipOptions = {
  titleMarginBottom: 10,
  titleColor: "#6e707e",
  titleFont: {
    size: 14,
    family: "Metropolis",
  },
  bodyFont: {
    family: "Metropolis",
  },
  backgroundColor: "rgb(255,255,255)",
  bodyColor: "#858796",
  borderColor: "#dddfeb",
  borderWidth: 1,
  displayColors: false,
  caretPadding: 10,
  padding: 15,
  labels: {
    color: "#858796",
    font: {
      family: "Metropolis",
    },
  },
};

export const generalOptions = {
  normalized: true,
  animation: false,
  maintainAspectRatio: false,
};

export const axesOptions = {
  maxTicksLimitX: 7,
  maxTicksLimitY: 7,
  enabledGrid: {
    color: "rgba(220, 222, 230, 1)",
    drawBorder: false,
    borderDash: [2],
  },

  disabledGrid: {
    display: false,
    drawBorder: false,
  },
};

export const zoomOptions = {
  zoomEnabled: false,
  panEnabled: false,
};

export const layoutOptions = {
  normalLayout: {
    padding: {
      left: 10,
      right: 25,
      top: 0,
      bottom: 0,
    },
  },
  bottomSpaceLayout: {
    padding: {
      left: 10,
      right: 25,
      top: 0,
      bottom: 30,
    },
  },
  leftSpaceLayout: {
    padding: {
      left: 100,
      right: 25,
      top: 0,
      bottom: 0,
    },
  },
};
