import React from "react";
import { useNavigate } from "react-router";

export default function Footer(props) {
  let navigate = useNavigate();

  const onPrivacyPolicyPressed = (event) => {
    event.preventDefault();
    navigate("/privacy-policy");
  };

  const onTermsAndConditionsPressed = (event) => {
    event.preventDefault();
    navigate("/terms-and-conditions");
  };

  return (
    <footer className={props.light ? "footer-admin mt-auto footer-light" : "footer-admin mt-auto footer-dark"}>
      <div className="container-xl px-4">
        <div className="row">
          <div className="col-md-6 small">Copyright © StrideLink 2024</div>
          {/* <div className="col-md-6 text-md-end small">
            <span onClick={onPrivacyPolicyPressed}>Privacy Policy</span>·<span onClick={onTermsAndConditionsPressed}>Terms &amp; Conditions</span>
          </div> */}
        </div>
      </div>
    </footer>
  );
}
