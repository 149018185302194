import Navbar from "../components/NavBars/Navbar";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import SideNavbar from "../components/NavBars/SideNavbar";
import Footer from "../components/Footer";
import PatientHeader from "../components/Headers/PatientHeader";
import PatientNavbar from "../components/NavBars/PatientNavbar";
import "chartjs-adapter-date-fns";
import Global from "../utils/Global";
import { TrialTable } from "../components/Tables/TrialTable";
import display_types from "../config/display_types";
import DropdownTable from "../components/Tables/DropdownTable";

// Chart.register(chartDataLabels);
export default function Reports() {
  const [isLoading, setIsLoading] = useState(true);
  const [subject, setSubject] = useState(null);
  const [periodInformation, setPeriodInformation] = useState([]);
  const display_type = Global.localAppHandler.user.display_type;

  const [selectedRowName, setSelectedRowName] = useState("");
  const [selectedRowId, setSelectedRowId] = useState("");
  const [trials, setTrials] = useState(null);

  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const subject = await Global.localAppHandler.app_get_subject();
        const periodInformation = await Global.localAppHandler.app_retrieve_period_information({ include_all_periods: true, include_trials: true, include_report: false });
        const filteredPeriods = periodInformation.filter((period) => {
          const startDate = new Date(period.start_date); // Convert start date to Date object
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          return startDate <= today; // Keep periods where start_date is before or on today
        });
        const trials = await Global.localAppHandler.app_get_trial_list();
        // Process and set state as necessary
        setSubject(subject);
        setPeriodInformation(filteredPeriods);
        setTrials(trials);
      } catch (error) {
        navigate("/dashboard");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  useEffect(() => {
    document.body.classList.add("nav-fixed");

    return () => {
      document.body.classList.remove("nav-fixed");
    };
  }, []);

  return (
    <>
      <Navbar />
      <div id="layoutSidenav">
        <SideNavbar
          patientSubHeaderEnabled={true}
          activeTab={{ studyOverview: false, subjects: true, manageTeam: false, reviewReports: false, remoteAlerts: false }}
        />
        <div id="layoutSidenav_content">
          <main>
            <PatientHeader
              bg={"bg-white"}
              subjectName={
                display_type === display_types.PHI
                  ? subject?.first_name && subject?.last_name
                    ? `${subject.first_name} ${subject.last_name}`
                    : ""
                  : subject?.name
              }
            />
            {/* Main page content*/}
            <div className="container-xl px-4 mt-n10">
              <PatientNavbar activeTab={{ overview: false, trials: false, detailed: false, gait_metrics: false, reports: true }} />
              {periodInformation.length > 0 && (display_type === display_types.PHI || display_type === display_types.NO_PHI_COLORADO) ? (
                periodInformation.reverse().map((period, index) => (
                  <DropdownTable
                    key={index}
                    title={
                      index === 0
                        ? `Current Period: ${period.start_date} - ${period.end_date}`
                        : `${period.start_date} - ${period.end_date}`
                    }
                    data={period.trials || []} // Assuming each period has a 'data' field
                    startOpen={index === 0} // Only open the first one
                    report={null} // Adjust this based on your logic
                  />
                ))
              ) : (
                <div className="card mb-4">
                <div className="card-body">
                  <TrialTable
                    trials={trials ? trials : []}
                    allowTwoSelections={false}
                    setSelectedRowName={setSelectedRowName}
                    setSelectedRowId={setSelectedRowId}
                    isLoading={isLoading}
                  />
                </div>
              </div>
              )}
            </div>
          </main>

          <Footer light={true} />
        </div>
      </div>
    </>
  );
}
