import Navbar from "../components/NavBars/Navbar";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import SideNavbar from "../components/NavBars/SideNavbar";
import Footer from "../components/Footer";
import PatientHeader from "../components/Headers/PatientHeader";
import PatientNavbar from "../components/NavBars/PatientNavbar";
import Chart from "chart.js/auto";
import "chartjs-adapter-date-fns";
import Global from "../utils/Global";
import dataLabelPlugin from 'chartjs-plugin-datalabels';
import { SubjectDemographicsTable } from "../components/Tables/SubjectDemographicsTable";
import { RemoteCollectionTable } from "../components/Tables/RemoteCollectionTable";
import StanceTimeBarGraph from "../components/Graphs/StanceTimeBarGraph";
import SwingASIGraph from "../components/Graphs/SwingASIGraph";
import StanceASIGraph from "../components/Graphs/StanceASIGraph";
import PainPRO from "../components/Graphs/PainPRO";
import Trial from "../utils/Trial";
import display_types from "../config/display_types";

Chart.register(dataLabelPlugin);

export default function GaitMetrics() {
  const [isLoading, setIsLoading] = useState(true);
  const [subject, setSubject] = useState(null);
  const [metrics, setMetrics] = useState(null);
  const [periodInformation, setPeriodInformation] = useState(null);
  const display_type = Global.localAppHandler.user.display_type;

  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const subject = await Global.localAppHandler.app_get_subject();
        const trialList = await Global.localAppHandler.app_get_trial_list({ include_metrics: true });
        const extracted_metrics = Trial.extractMetricsFromList(trialList);
        const periodInformation = await Global.localAppHandler.app_retrieve_period_information({ include_current_period: true, include_all_periods: true });
        setSubject(subject);
        setMetrics(extracted_metrics);
        setPeriodInformation(periodInformation)
      } catch (error) {
        navigate("/dashboard");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  useEffect(() => {
    document.body.classList.add("nav-fixed");

    return () => {
      document.body.classList.remove("nav-fixed");
    };
  }, []);

  const [activeTab, setActiveTab] = useState("alltime");

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <>
      <Navbar />
      <div id="layoutSidenav">
        <SideNavbar
          patientSubHeaderEnabled={true}
          activeTab={{ studyOverview: false, subjects: true, manageTeam: false, reviewReports: false, remoteAlerts: false }}
        />
        <div id="layoutSidenav_content">
          <main>
          <PatientHeader bg={"bg-white"} subjectName={display_type === display_types.PHI ? (subject?.first_name && subject?.last_name ? `${subject.first_name} ${subject.last_name}` : "") : subject?.name} />
          {/* Main page content*/}
            <div className="container-xl px-4 mt-n10">
              <PatientNavbar activeTab={{ overview: false, trials: false, detailed: false, gait_metrics: true, reports: false }} />
              <div className="card mb-4">
                <div className="card-body">
                  <SubjectDemographicsTable subject={subject} displayType={display_type} />
                </div>
              </div>

              <div className="overviewChartBox">
                <div className="row mb-4">
                  <div className="d-flex justify-content-center">
                    <div className="btn-group" role="group" aria-label="Basic example">
                      <div className={`btn btn-primary ${activeTab === "alltime" ? "active" : ""}`} onClick={() => handleTabClick("alltime")}>
                        All Time
                      </div>
                      <div className={`btn btn-primary ${activeTab === "7day" ? "active" : ""}`} onClick={() => handleTabClick("7day")}>
                        7 Day
                      </div>
                      <div className={`btn btn-primary ${activeTab === "30day" ? "active" : ""}`} onClick={() => handleTabClick("30day")}>
                        30 Day
                      </div>
                      <div className={`btn btn-primary ${activeTab === "90day" ? "active" : ""}`} onClick={() => handleTabClick("90day")}>
                        90 Day
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <StanceASIGraph
                      labels={metrics ? metrics.dates : []}
                      data={metrics ? metrics.stance_asis : []}
                      trialNames={metrics ? metrics.trial_names : []}
                      lastUpdatedTime={""}
                      timeAxis={"day"}
                      timeFrame={activeTab}
                    />
                  </div>
                  <div className="col-lg-6">
                    <RemoteCollectionTable periods={periodInformation} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <SwingASIGraph
                      labels={metrics ? metrics.dates : []}
                      data={metrics ? metrics.swing_asis : []}
                      trialNames={metrics ? metrics.trial_names : []}
                      lastUpdatedTime={""}
                      timeAxis={"day"}
                      timeFrame={activeTab}
                    />
                  </div>
                  <div className="col-lg-6">
                    <PainPRO
                      labels={metrics ? metrics.dates : []}
                      data={metrics ? metrics.vas_scores : []}
                      trialNames={metrics ? metrics.trial_names : []}
                      lastUpdatedTime={""}
                      timeAxis={"day"}
                      timeFrame={activeTab}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <StanceTimeBarGraph
                      labels={metrics ? metrics.dates : []}
                      data_l={metrics ? metrics.stance_times_left : []}
                      data_r={metrics ? metrics.stance_times_right : []}
                      trialNames={metrics ? metrics.trial_names : []}
                      lastUpdatedTime={""}
                      timeAxis={"day"}
                      timeFrame={activeTab}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="card mb-4">
              <div className="d-flex card-header align-items-center justify-content-between">FAOS Scores</div>
                <div className="card-body">
                  <PROTable
                    values={[
                      { faos: 98, symptoms: 100, pain: 96, daily: 56, sports: 100, qol: 88, date: "11/23/2023" },
                      { faos: 98, symptoms: 100, pain: 96, daily: 56, sports: 100, qol: 88, date: "11/23/2023" },
                      { faos: 98, symptoms: 100, pain: 96, daily: 56, sports: 100, qol: 88, date: "11/23/2023" },
                    ]}
                  />
                </div>
              </div> */}
            </div>
          </main>

          <Footer light={true} />
        </div>
      </div>
    </>
  );
}
