import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { passwordValidator } from "../utils/validators/passwordValidator";
import { confirmPasswordValidator } from "../utils/validators/confirmPasswordValidator";
import Footer from "../components/Footer";
import Global from "../utils/Global";
import auth_errors from "../config/auth_errors";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons

export default function ForceChangePassword() {
  const [password, setPassword] = useState({ value: "", error: "" });
  const [confirmPassword, setConfirmPassword] = useState({ value: "", error: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    document.body.classList.add("bg-primary");
    if (!location.state || !location.state.username || !location.state.password) {
      navigate("/login");
    }

    return () => {
      document.body.classList.remove("bg-primary");
    };
  }, [navigate]);

  const onReturnPressed = (event) => {
    event.preventDefault();
    navigate("/login");
  };

  const onResetPressed = (event) => {
    event.preventDefault();
    const passwordError = passwordValidator(password.value);
    const confirmPasswordError = confirmPasswordValidator(password.value, confirmPassword.value);
    if (passwordError || confirmPasswordError) {
      setPassword({ ...password, error: passwordError });
      setConfirmPassword({ ...confirmPassword, error: confirmPasswordError });
      return;
    }

    if (loading) {
      return;
    }

    setLoading(true);

    Global.localAppHandler
      .app_handle_force_change_password(confirmPassword.value)
      .then((returnStatus) => {
        // navigate("/login");
      })
      .catch((auth_error) => {
        if (auth_error === auth_errors.new_pass_failed) {
          setConfirmPassword({
            value: confirmPassword.value,
            error: "Please select a different password.",
          });
        } else {
          setConfirmPassword({
            value: confirmPassword.value,
            error: "There was an error setting the password. Please try again.",
          });
          // TODO: could be non auth_error errors too, handle this better
        }
        setLoading(false);
      });
  };
  return (
    <>
      <div>
        <div id="layoutAuthentication">
          <div id="layoutAuthentication_content">
            <main>
              <div className="container-xl px-4">
                <div className="row justify-content-center">
                  <div className="col-lg-5">
                    {/* Basic forgot password form*/}
                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                      <div className="card-header justify-content-center">
                        <h3 className="fw-light my-4">Set New Password</h3>
                      </div>
                      <div className="card-body">
                        <div className="small mb-3 text-muted">Please set a new password for your account.</div>
                        {/* Forgot password form*/}
                        <form onSubmit={onResetPressed}>
                          {/* Form Group (email address)*/}
                          <div className="mb-3">
                            <label className="small mb-1" htmlFor="inputPassword">
                              Password
                            </label>
                            <div className="input-group">
                              <input
                                value={password.value}
                                onChange={(event) => setPassword({ value: event.target.value.replace(/\s/g, ""), error: "" })}
                                className="form-control"
                                id="inputPassword"
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                              />
                              <span
                                className="input-group-text"
                                onClick={() => setShowPassword((prev) => !prev)}
                                aria-label={showPassword ? "Hide password" : "Show password"}
                                style={{ cursor: "pointer" }} // Makes the icon clickable
                              >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                              </span>
                            </div>
                          </div>
                          {password.error.length > 0 && <div className="tiny alert alert-danger">{password.error}</div>}
                          <div className="mb-3">
                            <label className="small mb-1" htmlFor="inputConfirmPassword">
                              Confirm Password
                            </label>
                            <div className="input-group">
                              <input
                                value={confirmPassword.value}
                                onChange={(event) => setConfirmPassword({ value: event.target.value.replace(/\s/g, ""), error: "" })}
                                className="form-control"
                                id="inputConfirmPassword"
                                type={showConfirmPassword ? "text" : "password"}
                                placeholder="Confirm Password"
                              />
                              <span
                                className="input-group-text"
                                onClick={() => setShowConfirmPassword((prev) => !prev)}
                                aria-label={showConfirmPassword ? "Hide password" : "Show password"}
                                style={{ cursor: "pointer" }} // Makes the icon clickable
                              >
                                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                              </span>
                            </div>
                          </div>
                          {confirmPassword.error.length > 0 && <div className="tiny alert alert-danger">{confirmPassword.error}</div>}

                          {/* Form Group (submit options)*/}
                          <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                            <div className="small link-primary" aria-disabled={loading} onClick={onReturnPressed}>
                              Return to login
                            </div>
                            <div className={`btn btn-primary ${loading ? "disabled" : ""}`} aria-disabled={loading} onClick={onResetPressed}>
                              {loading ? "Loading..." : "Reset Password"}
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="card-footer text-center"></div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
          <div id="layoutAuthentication_footer">
            <Footer light={false} />
          </div>
        </div>
      </div>
    </>
  );
}
