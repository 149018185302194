import React from "react";
import account_status from "../../config/account_status";
import PatchForm from "../../utils/Form/PatchForm";

const ConfirmAccountStatusModal = ({ isOpen, handleClose, handleSave, subject, subjectForm, setSubjectForm, errorText, setErrorText }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Confirm Account Status Change</h5>
            <button className="btn-close" onClick={handleClose} aria-label="Close"></button>
          </div>
          {subject.account_status === account_status.active || subject.account_status === account_status.pending ? (
            <div className="modal-body">
              <p>By deactivating a user account you confirm that their episode of care has ended. Remote users will lose account access.</p>
              <p>You wish to deactivate {subject.name}'s account?</p>
            </div>
          ) : (
            <div className="modal-body">
              <p>By activating a user account you confirm that you are beginning or resuming an episode of care. Active remote users may incur charges.</p>
              <p>You wish to activate {subject.name}'s account?</p>
            </div>
          )}
          {errorText && <div className="tiny alert alert-danger">{errorText}</div>}

          <div className="modal-footer">
            <button className="btn btn-secondary" onClick={handleClose}>
              Cancel
            </button>

            {subject.account_status === account_status.active || subject.account_status === account_status.pending ? (
              <button
                className={"btn btn-danger"}
                onClick={() => {
                  subjectForm.account_status.handleInputChange(account_status.inactive); // Handling the input as a string
                  setSubjectForm(PatchForm.copy(subjectForm));
                  handleSave();
                }}
              >
                Deactivate
              </button>
            ) : (
              <button
                className={"btn btn-primary"}
                onClick={() => {
                  subjectForm.account_status.handleInputChange(account_status.active); // Handling the input as a string
                  setSubjectForm(PatchForm.copy(subjectForm));
                  handleSave();
                }}
              >
                Activate
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmAccountStatusModal;
