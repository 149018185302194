import React, { useEffect, useRef, useState, useMemo } from "react";
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import TreatmentModal from "../Modals/TreatmentModal";
import Treatment from "../../utils/Treatment";
import data_cpt from "../../config/CPT-2023.json";
import { FiDelete } from "react-icons/fi/";

const ITEM_SIZE = 60;

const TreatmentSearchableDropdown = ({ placeholder, onItemAdded, onItemReplaced, onItemDeleted, treatments, errorText }) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const treatment = useRef(null);
  const [intermediateOption, setIntermediateOption] = useState(null);

  const [weeksPostOp, setWeeksPostOp] = useState({ value: "", error: "" });
  const [affectedSide, setAffectedSide] = useState({value:"", error: ""});
  
  const inputRef = useRef(null);
  const optionRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleClickOutside = (e) => {
    if (inputRef.current.contains(e.target) || (optionRef.current && optionRef.current.contains(e.target))) {
      return;
    }
    setIsOpen(false);
  };

  const selectOption = (option, index) => {
    setQuery(() => "");
    setIsOpen((isOpen) => !isOpen);
    setSelectedIndex(index);
    setIntermediateOption(option);
    setIsModalOpen(true);
  };

  const getDisplayValue = () => {
    if (query) return query;
    // if (selectedVal) return selectedVal;

    return "";
  };

  const options = Object.keys(data_cpt.codes).map((code) => ({
    code_type: data_cpt["code-type"],
    code_value: code,
    description: data_cpt.codes[code],
  }));

  const filteredOptions = useMemo(() => {
    return options.filter(
      (option) => option["description"] && (option["code_value"].toLowerCase() + ": " + option["description"]).toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  }, [options, query]);

  return (
    <>
      <div className="row gx-3 mb-3">
        <div className="col-md-12">
          <label className="small mb-1" htmlFor="inputAge">
            Primary CPT Code(s):
          </label>
          <div className="dropdown">
            <div className="control" ref={inputRef}>
              <input
                className="form-control"
                type="text"
                value={getDisplayValue()}
                name="searchTerm"
                autoComplete="off"
                onChange={(e) => {
                  setQuery(e.target.value);
                  treatment.current = null;
                }}
                placeholder={placeholder}
                onClick={() => setIsOpen(true)}
              />
            </div>

            {isOpen && (
              <div className={`options ${isOpen ? "open" : ""}`} ref={optionRef}>
                <List
                  height={300} // Height of the visible window
                  itemCount={filteredOptions.length} // Total number of items
                  itemSize={ITEM_SIZE} // Height of each item
                  width="100%" // Width of the container
                >
                  {({ index, style }) => {
                    const option = filteredOptions[index];
                    return (
                      <div
                        key={`${"code_value"}-${index}`}
                        onClick={() => selectOption(option, index)}
                        className={`option ${selectedIndex === index ? "selected" : ""}`}
                        style={style}
                      >
                        {option["code_value"] + ": " + option["description"]}
                      </div>
                    );
                  }}
                </List>
              </div>
            )}

            <TreatmentModal
              isOpen={isModalOpen}
              handleClose={() => {
                setIsModalOpen(false);
                setIntermediateOption(null);
              }}
              handleSave={(weeksPostOp, affectedSide) => {
                if (intermediateOption) {
                  const newTreatment = new Treatment({
                    code_type: intermediateOption["code_type"],
                    code_value: intermediateOption["code_value"],
                    weeks_post_op: weeksPostOp,
                    sls_modifier: affectedSide,
                    description: intermediateOption["description"]
                });
                  onItemAdded(newTreatment);
                }
                setIsModalOpen(false);
                setIntermediateOption(null);
              }}
              selectedOption={intermediateOption && intermediateOption["code_value"] + ": " + intermediateOption["description"]}
              weeksPostOp={weeksPostOp}
              setWeeksPostOp={setWeeksPostOp}
              affectedSide={affectedSide}
              setAffectedSide={setAffectedSide}
            />

<TreatmentModal
              isOpen={isModalOpen2}
              handleClose={() => {
                setIsModalOpen2(false);
                setIntermediateOption(null);
              }}
              handleSave={(weeksPostOp, affectedSide) => {
                if (intermediateOption) {
                  const newTreatment = new Treatment({
                    code_type: intermediateOption["code_type"],
                    code_value: intermediateOption["code_value"],
                    weeks_post_op: weeksPostOp,
                    sls_modifier: affectedSide,
                    description: intermediateOption["description"]
                });
                  onItemReplaced(newTreatment);
                }
                setIsModalOpen2(false);
                setIntermediateOption(null);
              }}
              selectedOption={intermediateOption && intermediateOption["code_value"] + ": " + intermediateOption["description"]}
              weeksPostOp={weeksPostOp}
              setWeeksPostOp={setWeeksPostOp}
              affectedSide={affectedSide}
              setAffectedSide={setAffectedSide}
            />
          </div>
          <div className="mb-2"></div>
          {errorText && <div className="tiny alert alert-danger">{errorText}</div>}
        </div>
      </div>
      
      <div className="tagsContainer">
      {treatments
    ? treatments.map((entry) => (
        <div key={entry.code_value} className="tag" onClick={(e) => {
          e.stopPropagation(); // Stop click from bubbling up to elements behind
          setIsModalOpen2(true);
          setIntermediateOption(entry);
          setWeeksPostOp({ value: entry.weeks_post_op ? entry.weeks_post_op.toString() : "", error: "" });
          setAffectedSide({value: entry.sls_modifier ? entry.sls_modifier : "", error: ""});
        }}>
          {/* Delete button */}
          <button
            className="btn deleteBtn"
            onClick={(e) => {
              e.stopPropagation(); // Prevent click from triggering parent's onClick
              e.preventDefault(); // Prevent default button behavior
              onItemDeleted(entry);
            }}
          >
            <FiDelete />
          </button>
          {/* Display the code_value */}
          <span className="tagText">{entry.code_value}</span>
        </div>
      ))
    : null}
      </div>
    </>
  );
};

export default TreatmentSearchableDropdown;
