import React, { useState } from "react";
import PatchForm from "../../utils/Form/PatchForm";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const EditAccountPhoneModal = ({ isOpen, handleClose, handleSave, subjectForm, setSubjectForm, errorText, setErrorText }) => {
  if (!isOpen) return null;

  return (
    <>
      <div className="modal-overlay" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Edit Account Status:
              </h5>
              <button className="btn-close" type="button" aria-label="Close" onClick={handleClose}></button>
            </div>
            <div className="modal-body">
              <p>This number will receive a text message to activate a remote patient account. Ensure it is for the correct patient before proceeding.</p>
              
              <PhoneInput
                        country="US"
                        countries={["US"]}
                        defaultCountry="US"
                        addInternationalOption={false}
                        limitMaxLength={true}
                        // international={false}
                        // countryCallingCodeEditable={false}
                        placeholder="Enter phone number"
                        value={subjectForm.phone_number.getUI()}
                        onChange={(value) => {
                          setErrorText("")
                          subjectForm.phone_number.handleInputChange(value); // Handling the input as a string
                          setSubjectForm(PatchForm.copy(subjectForm));
                        }}
                        style={{marginBottom: 10}}
                      />
              <div>
                {subjectForm.phone_number.error && <div className="tiny alert alert-danger">{subjectForm.phone_number.error}</div>}
                {!subjectForm.phone_number.error && errorText && <div className="tiny alert alert-danger">{errorText}</div>}
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn btn-secondary" type="button" onClick={handleClose}>
                Cancel
              </button>
              <button className="btn btn-primary" type="button" onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAccountPhoneModal;
