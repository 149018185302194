import React, { useState, useRef, useEffect, useMemo } from "react";
import { Line } from "react-chartjs-2";
import { tooltipOptions, generalOptions, axesOptions, zoomOptions, layoutOptions } from "../../assets/chart-defaults";
import Chart, { ChartData, ChartOptions } from "chart.js/auto";

export default function StanceTimeBarGraph({ labels, data_l, data_r, trialNames, lastUpdatedTime, timeAxis, timeFrame, ...props }) {
  const [minDateStr, maxDateStr] = useMemo(() => {
    const dates = labels.map(label => new Date(label)).filter(date => !isNaN(date.getTime()));
    let min, max;
    if (timeFrame === "alltime") {
      if (dates.length > 0) {
        min = new Date(Math.min(...dates.map(date => date.getTime())));
        max = new Date(Math.max(...dates.map(date => date.getTime())));
      } else {
        // Set to default values if no valid dates are found
        min = new Date();
        max = new Date();
      }
    } else if (timeFrame === "7day") {
      if (dates.length > 0) {
        max = new Date();
        min = new Date(max);
        min.setDate(min.getDate() - 7);
      } else {
        // Set to default values if no valid dates are found
        min = new Date();
        max = new Date();
      }
    } else if (timeFrame === "30day") {
      if (dates.length > 0) {
        max = new Date();
        min = new Date(max);
        min.setDate(min.getDate() - 30);
      } else {
        // Set to default values if no valid dates are found
        min = new Date();
        max = new Date();
      }
    } else if (timeFrame === "90day") {
      if (dates.length > 0) {
        max = new Date();
        min = new Date(max);
        min.setDate(min.getDate() - 90);
      } else {
        // Set to default values if no valid dates are found
        min = new Date();
        max = new Date();
      }
    }
    const range = max.getTime() - min.getTime();
    let padding = 0.2 * range / (1000 * 60 * 60 * 24); // converting from ms to days

    if (padding < 2) {
      padding = 2;
    }

    min.setDate(min.getDate() - padding);
    max.setDate(max.getDate() + padding);

    return [min.toISOString(), max.toISOString()];
  }, [labels, timeFrame]);
  
  const stanceTimeRef = useRef<Chart<'line'>>(null);
  const [lastUpdatedTimeNew, setLastUpdatedTimeNew] = useState<string>(lastUpdatedTime);
  
  const placeholder_stanceTimeData = useMemo<ChartData<'bar'>>(
    () => ({
      labels: labels.length > 0 ? labels : [],
      datasets: [
        {
          type: "bar",
          label: "Left Stance Times",
          backgroundColor: "rgba(0,97,242,1)", // First color
          borderColor: "rgba(0,97,242,1)",
          data: data_l.length > 0 ? data_l : [],
        },
        {
          type: "bar",
          label: "Right Stance Times",
          backgroundColor: "rgba(255,99,132,1)", // Second color
          borderColor: "rgba(255,99,132,1)",
          data: data_r.length > 0 ? data_r : [], // Same data as the first dataset
        }
      ],
    }),
    [labels, data_l, data_r] // Update the dependency array if necessary
  );

  const placeholder_stanceTimeOptions = useMemo<ChartOptions<'line'>>(
    () => ({
      normalized: generalOptions.normalized,
      animation: generalOptions.animation,
      maintainAspectRatio: generalOptions.maintainAspectRatio,
      layout: layoutOptions.normalLayout,
      scales: {
        x: {
          type: "time",
          time: {
            unit: timeAxis,
          },
          min: minDateStr, // Set min date
          max: maxDateStr, // Set max date
          grid: axesOptions.disabledGrid,
          ticks: {
            maxTicksLimit: axesOptions.maxTicksLimitX,
          },
          // offset: true
          // title: {
          //   display: true,
          //   text: 'Weeks Post-Op'
          // }
        },
        y: {
          suggestedMin: 0,
          // max: 400,
          title: {
            display: true,
            text: 'Stance time (ms)',  // Replace with your desired label
          },
          ticks: {
            maxTicksLimit: axesOptions.maxTicksLimitY,
            padding: 20,
            callback: function (value, index, values) {
              return Math.abs(value);
            },
          },
          grid: axesOptions.enabledGrid,
        },
      },
      plugins: {
        legend: {
          display: true, // You might want to display the legend for clarity
        },
        tooltip: {
          titleMarginBottom: tooltipOptions.titleMarginBottom,
          titleColor: tooltipOptions.titleColor,
          titleFont: tooltipOptions.titleFont,
          bodyFont: tooltipOptions.bodyFont,
          backgroundColor: tooltipOptions.backgroundColor,
          bodyColor: tooltipOptions.bodyColor,
          borderColor: tooltipOptions.borderColor,
          borderWidth: tooltipOptions.borderWidth,
          displayColors: tooltipOptions.displayColors,
          caretPadding: tooltipOptions.caretPadding,
          padding: tooltipOptions.padding,
          callbacks: {
            title: function (tooltipItems) {
              let trialName = trialNames.length > 0 ? trialNames[tooltipItems[0].dataIndex] : null;
              if (!trialName) {
                    trialName = "Unknown"; // Fallback if name is not available
                }
                return trialName;
            },
            label: function (context) {
                let splitIndex = context.label.lastIndexOf(",");
                let labelValue = context.label;
        
                if (timeAxis === "hour") {
                    labelValue = context.label;
                } else if (timeAxis === "day") {
                    labelValue = context.label.slice(0, splitIndex);
                } else if (timeAxis === "month") {
                    labelValue = context.label.slice(0, 4);
                } else if (timeAxis === "millisecond") {
                    labelValue = context.label;
                }
                return `${labelValue}: ${context.parsed.y} ms`;
            },
        },
        },
        datalabels: {
          display: true,
          color: 'white', // Set the text color to white
          font: {
            weight: 'bold', // Make the text bold
            // You can also set the font size if needed, e.g., size: 12
          },
          formatter: (value, context) => {
            const minDate = new Date(minDateStr)
            const maxDate = new Date(maxDateStr)
            const differenceInTime = maxDate.getTime() - minDate.getTime();
            const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);
            // if (Math.round(differenceInDays) <= 17) { // 17 because 7 days w/ 5 days padding on either side
            //   return value; // Format the datalabel text
            // } else {
              return ""
            // }
          },
        },
      },
    }),
    [timeAxis, trialNames, minDateStr, maxDateStr]
  );

  useEffect(() => {
    setStanceTimeData(placeholder_stanceTimeData);
    setStanceTimeOptions(placeholder_stanceTimeOptions);
  }, [placeholder_stanceTimeData, placeholder_stanceTimeOptions]);

  useEffect(() => {
    setLastUpdatedTimeNew(lastUpdatedTime);
  }, [lastUpdatedTime]);

  const [stanceTimeData, setStanceTimeData] = useState<ChartData<'line'>>(placeholder_stanceTimeData);
  const [stanceTimeOptions, setStanceTimeOptions] = useState<ChartOptions<'line'>>(placeholder_stanceTimeOptions);

  return (
    <div className="card mb-4">
      <div className="stanceTimeGraph">
      <div className="d-flex card-header align-items-center justify-content-between">
    Stance Times
    <div className="d-flex align-items-center">
        {zoomOptions.panEnabled || zoomOptions.zoomEnabled ? (
            <button
                className="btn btn-transparent-dark rounded-pill"
                type="button"
                onClick={() => {
                    if (stanceTimeRef.current) {
                        stanceTimeRef.current.resetZoom();
                    }
                }}
            >
                Reset
            </button>
        ) : (
            ""
        )}
    </div>
</div>
        <div className="card-body">
        <div className="chart-pie" style={{ position: 'relative' }}>
    <Line ref={stanceTimeRef} data={stanceTimeData} height={50} width={100} options={stanceTimeOptions}></Line>
</div>
        </div>
        <div className="card-footer small text-muted">{lastUpdatedTimeNew}</div>
      </div>
    </div>
  );
}
