import React from "react";
import { Menu, User, Settings, DollarSign, LogOut, Lock } from "react-feather";
import { useNavigate } from "react-router";
import { Auth } from "aws-amplify";
import Global from "../../utils/Global"
export default function Navbar() {
  let navigate = useNavigate();

  const profilePressed = (event) => {
    event.preventDefault();
    navigate("/profile");
  };

  const billingPressed = (event) => {
    event.preventDefault();
    navigate("/billing");
  };

  const dashboardPressed = (event) => {
    event.preventDefault();
    navigate("/dashboard");
  };

  const securityPressed = (event) => {
    event.preventDefault();
    navigate("/security");
  };

  const onToggleClicked = (event) => {
    event.preventDefault();
    document.body.classList.toggle("sidenav-toggled");
    localStorage.setItem("sb|sidebar-toggle", String(document.body.classList.contains("sidenav-toggled")));
  };

  const logoutPressed = () => {
    Global.localAppHandler.app_handle_logout().then((returnStatus) => {
    }).catch((returnStatus) => {
      // should never happen.
    })  };

  return (
    //
    <div>
      <nav className="topnav navbar navbar-expand shadow justify-content-between justify-content-sm-start navbar-light bg-white" id="sidenavAccordion">
        {/* Sidenav Toggle Button*/}
        <button onClick={onToggleClicked} className="btn btn-icon btn-transparent-dark order-1 order-lg-0 me-2 ms-lg-2 me-lg-0" id="sidebarToggle">
          <Menu />
        </button>
        {/* Navbar Brand*/}
        <div className="navbar-brand pe-3 ps-4 ps-lg-2" onClick={dashboardPressed}>
          <div className="StrideLinkLogo">
            <img className="img-fluid" alt="Logo" src={require("../../assets/img/Logo_Final-2.png")} style={{ width: 160, height: 48 }} />
          </div>
        </div>
        {/* Navbar Search Input*/}
        {/* * * Note: * * Visible only on and above the lg breakpoint*/}
        {/* Navbar Items*/}
        <ul className="navbar-nav align-items-center ms-auto">
          {/* Alerts Dropdown*/}
          {/* <li className="nav-item dropdown no-caret d-none d-sm-block me-3 dropdown-notifications">
            <div
              className="btn btn-icon btn-transparent-dark dropdown-toggle"
              id="navbarDropdownAlerts"
              href="javascript:void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <Bell />
            </div>
            <div className="dropdown-menu dropdown-menu-end border-0 shadow animated--fade-in-up" aria-labelledby="navbarDropdownAlerts">
              <h6 className="dropdown-header dropdown-notifications-header">
                <i className="me-2">
                  <Bell />
                </i>
                Alerts Center
              </h6>
              <a className="dropdown-item dropdown-notifications-item" href="#!">
                <div className="dropdown-notifications-item-icon bg-warning">
                  <Activity />
                </div>
                <div className="dropdown-notifications-item-content">
                  <div className="dropdown-notifications-item-content-details">December 29, 2021</div>
                  <div className="dropdown-notifications-item-content-text">
                    This is an alert message. It's nothing serious, but it requires your attention.
                  </div>
                </div>
              </a>
              <a className="dropdown-item dropdown-notifications-item" href="#!">
                <div className="dropdown-notifications-item-icon bg-info">
                  <BarChart />
                </div>
                <div className="dropdown-notifications-item-content">
                  <div className="dropdown-notifications-item-content-details">December 22, 2021</div>
                  <div className="dropdown-notifications-item-content-text">A new monthly report is ready. Click here to view!</div>
                </div>
              </a>
              <a className="dropdown-item dropdown-notifications-item" href="#!">
                <div className="dropdown-notifications-item-icon bg-danger">
                  <i className="fas fa-exclamation-triangle" />
                </div>
                <div className="dropdown-notifications-item-content">
                  <div className="dropdown-notifications-item-content-details">December 8, 2021</div>
                  <div className="dropdown-notifications-item-content-text">Critical system failure, systems shutting down.</div>
                </div>
              </a>
              <a className="dropdown-item dropdown-notifications-item" href="#!">
                <div className="dropdown-notifications-item-icon bg-success">
                  <UserPlus />
                </div>
                <div className="dropdown-notifications-item-content">
                  <div className="dropdown-notifications-item-content-details">December 2, 2021</div>
                  <div className="dropdown-notifications-item-content-text">New user request. Woody has requested access to the organization.</div>
                </div>
              </a>
              <a className="dropdown-item dropdown-notifications-footer" href="#!">
                View All Alerts
              </a>
            </div>
          </li> */}
          {/* Messages Dropdown*/}
          {/* <li className="nav-item dropdown no-caret d-none d-sm-block me-3 dropdown-notifications">
            <div
              className="btn btn-icon btn-transparent-dark dropdown-toggle"
              id="navbarDropdownMessages"
              href="javascript:void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <Settings />
            </div>
            <div className="dropdown-menu dropdown-menu-end border-0 shadow animated--fade-in-up" aria-labelledby="navbarDropdownMessages">
              <h6 className="dropdown-header dropdown-notifications-header">
                <i className="me-2">
                  <Mail />
                </i>
                Message Center
              </h6>
              <div className="dropdown-item dropdown-notifications-item" href="#!">
                <img className="dropdown-notifications-item-img" src={require("../../assets/img/illustrations/profiles/profile-2.png")} alt="" />
                <div className="dropdown-notifications-item-content">
                  <div className="dropdown-notifications-item-content-text">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                    voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </div>
                  <div className="dropdown-notifications-item-content-details">Thomas Wilcox · 58m</div>
                </div>
              </div>
              <div className="dropdown-item dropdown-notifications-item" href="#!">
                <img className="dropdown-notifications-item-img" src={require("../../assets/img/illustrations/profiles/profile-3.png")} alt="" />
                <div className="dropdown-notifications-item-content">
                  <div className="dropdown-notifications-item-content-text">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                    voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </div>
                  <div className="dropdown-notifications-item-content-details">Emily Fowler · 2d</div>
                </div>
              </div>
              <div className="dropdown-item dropdown-notifications-item" href="#!">
                <img className="dropdown-notifications-item-img" src={require("../../assets/img/illustrations/profiles/profile-4.png")} alt="" />
                <div className="dropdown-notifications-item-content">
                  <div className="dropdown-notifications-item-content-text">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                    voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </div>
                  <div className="dropdown-notifications-item-content-details">Marshall Rosencrantz · 3d</div>
                </div>
              </div>
              <div className="dropdown-item dropdown-notifications-item" href="#!">
                <img className="dropdown-notifications-item-img" src={require("../../assets/img/illustrations/profiles/profile-5.png")} alt="" />
                <div className="dropdown-notifications-item-content">
                  <div className="dropdown-notifications-item-content-text">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                    voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </div>
                  <div className="dropdown-notifications-item-content-details">Colby Newton · 3d</div>
                </div>
              </div>
              <div className="dropdown-item dropdown-notifications-footer" href="#!">
                Read All Messages
              </div>
            </div>
          </li> */}
          {/* User Dropdown*/}
          <li className="nav-item dropdown no-caret dropdown-user me-3 me-lg-4">
            <div
              className="btn btn-icon btn-transparent-dark dropdown-toggle"
              id="navbarDropdownUserImage"
              // href="javascript:void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <User className="img-fluid" />
            </div>
            <div className="dropdown-menu dropdown-menu-end border-0 shadow animated--fade-in-up" aria-labelledby="navbarDropdownUserImage">
              <h6 className="dropdown-header d-flex align-items-center">
                <User className="dropdown-user-img" />
                <div className="dropdown-user-details">
                  <div className="dropdown-user-details-name">
                  {Global.localAppHandler && Global.localAppHandler.user ? Global.localAppHandler.user.name : ""}
                  </div>
                  <div className="dropdown-user-details-email">{Global.localAppHandler && Global.localAppHandler.authHandler.cog_user ? Global.localAppHandler.authHandler.cog_user.signInUserSession.idToken.payload.email : ""}</div>
                </div>
              </h6>
              <div className="dropdown-divider" />
              <div className="dropdown-item" onClick={profilePressed}>
                <div className="dropdown-item-icon">
                  <Settings className="mt-1" />
                </div>
                Profile
              </div>
              {/* <div className="dropdown-item" onClick={billingPressed}>
                <div className="dropdown-item-icon">
                  <DollarSign className="mt-1" />
                </div>
                Billing
              </div> */}
              <div className="dropdown-item" onClick={securityPressed}>
                <div className="dropdown-item-icon">
                  <Lock className="mt-1" />
                </div>
                Security
              </div>
              <div className="dropdown-item text-danger" onClick={logoutPressed}>
                <div className="dropdown-item-icon text-danger">
                  <LogOut className="mt-1" />
                </div>
                Logout
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
}
