import * as actions from "./actionTypes";

export default function reducer(state = {subjectList: null}, action) {
    switch (action.type) {
        case actions.UPDATE_USER_SUBJECTS:
            return {
                ...state,
                subjectList: JSON.parse(action.payload.subjectList),
            }
        case actions.RESET_STORE:
            return {
                ...state,
                subjectList: null,
            }
        default:
            return state;
    }
}