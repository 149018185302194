import FieldWrapperArray from "./FieldWrapperArray";
import FieldWrapperSingle from "./FieldWrapperSingle";

export default class Form {
  constructor({ properties }) {
    if (properties) {
      Object.entries(properties).forEach(([name, fieldWrapper]) => {
        this[name] = fieldWrapper
      });
    }
  }

static copy(oldForm) {
  const properties = {};
  for (const [key, fieldWrapper] of Object.entries(oldForm)) {
    // Check the instance type and create a new instance accordingly
    if (fieldWrapper instanceof FieldWrapperSingle) {
      properties[key] = new FieldWrapperSingle({
        attribute_name: fieldWrapper.attribute_name,
        value: fieldWrapper.value,
        error: fieldWrapper.error,
        value_validator: fieldWrapper.value_validator,
        // Add other necessary properties
      });
    } else if (fieldWrapper instanceof FieldWrapperArray) {
      properties[key] = new FieldWrapperArray({
        attribute_name: fieldWrapper.attribute_name,
        value: fieldWrapper.value, // Ensure this handles the array of values correctly
        error: fieldWrapper.error,
        value_validator: fieldWrapper.value_validator,
        // Add other necessary properties
      });
    }
  }

  const newForm = new Form({ properties: properties });
  return newForm;
}
}
