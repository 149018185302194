import React from "react";
import display_types from "../../config/display_types";
import calculateAge from "../../utils/helpers/CalculateAge";

export function SubjectDemographicsTable({ subject, displayType, ...props }) {
  return (
    <>
      <table className="table table-bordered">
        <thead>
          <tr>
            {displayType === display_types.PHI ? <th>Date of Birth</th> : null}
            <th>Age</th>
            <th>Height (in)</th>
            <th>Weight (lbs)</th>
            <th>Sex</th>
            <th>Primary Condition(s)</th>
            <th>Treatment(s)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {displayType === display_types.PHI ? <td>{subject ? subject.dob : ""}</td> : null}
            {displayType === display_types.PHI ? <td>{subject ? calculateAge(subject.dob) : ""}</td> : <td>{subject ? subject.age : ""}</td>}
            <td>{subject ? subject.height : ""}</td>
            <td>{subject ? subject.weight : ""}</td>
            <td>{subject ? subject.sex : ""}</td>
            <td>
              {subject && subject.primary_conditions && subject.primary_conditions.length > 0 ? ( 
                <>
                  {subject.primary_conditions.map((entry, index) => (
                    <React.Fragment key={index}>
                      <b>{entry.code_type + " " + entry.code_value + ": "}</b>
                      <br />
                      {"Date: " + (entry.approximate_date ? entry.approximate_date : "n/a")}
                      <br/>
                      {entry.description + (index < subject.primary_conditions.length - 1 ? ", " : "")}
                      <br/>
                    </React.Fragment>
                  ))}
                </>
              ) : (
                "No Primary Conditions on Record."
              )}
            </td>
            <td>
              {subject && subject.treatments && subject.treatments.length > 0 ? ( 
                <>
                  {subject.treatments.map((entry, index) => (
                    <React.Fragment key={index}>
                      <b>{entry.code_type + " " + entry.code_value + ": "}</b>
                      <br />
                      {"Weeks Post Op: " + entry.weeks_post_op}
                      <br/>
                      {entry.description + " (" + entry.sls_modifier + ")" + (index < subject.treatments.length - 1 ? ", " : "")}
                      <br/>
                    </React.Fragment>
                  ))}
                </>
              ) : (
                "No Treatments on Record."
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
