import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { passwordValidator } from "../utils/validators/passwordValidator";
import { pinValidator } from "../utils/validators/pinValidator";
import Footer from "../components/Footer";
import { confirmPasswordValidator } from "../utils/validators/confirmPasswordValidator";
import Global from "../utils/Global";
import auth_errors from "../config/auth_errors";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons

export default function ConfirmCode() {
  const [pin, setPin] = useState({ value: "", error: "" });
  const [password, setPassword] = useState({ value: "", error: "" });
  const [confirmPassword, setConfirmPassword] = useState({ value: "", error: "" });
  const [showPin, setShowPin] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const location = useLocation();
  const [searchparams] = useSearchParams();

  useEffect(() => {
    document.body.classList.add("bg-primary");
    if (!location.state || !location.state.username) {
      navigate("/recover");
    }
    return () => {
      window.localStorage.setItem("newPasswordAccessible", JSON.stringify(false));
      document.body.classList.remove("bg-primary");
    };
  }, [navigate, searchparams]);

  const onReturnPressed = (event) => {
    event.preventDefault();
    navigate("/login");
  };

  const onResetPressed = async (event) => {
    event.preventDefault();
    const pinError = pinValidator(pin.value);
    const passwordError = passwordValidator(password.value);
    const confirmPasswordError = confirmPasswordValidator(password.value, confirmPassword.value);
    if (pinError || passwordError || confirmPasswordError) {
      setPin({ ...pin, error: pinError });
      setPassword({ ...password, error: passwordError });
      setConfirmPassword({ ...confirmPassword, error: confirmPasswordError });
      return;
    }

    if (loading) {
      return;
    }
    setLoading(true);

    Global.localAppHandler
      .app_handle_forgot_password_submit(location.state.username, pin.value, password.value)
      .then((returnStatus) => {
        navigate("/login");
      })
      .catch((auth_error) => {
        if (auth_error === auth_errors.pin_error) {
          setPin({ value: pin.value, error: "Invalid verification code provided, please try again." });
        } else if (auth_error === auth_errors.too_many_attempts) {
          setPassword({ value: password.value, error: "Attempt limit exceeded, please try after some time." });
        } else {
          setPassword({ value: password.value, error: "There was an error, please try again." });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div>
        <div id="layoutAuthentication">
          <div id="layoutAuthentication_content">
            <main>
              <div className="container-xl px-4">
                <div className="row justify-content-center">
                  <div className="col-lg-5">
                    {/* Basic forgot password form*/}
                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                      <div className="card-header justify-content-center">
                        <h3 className="fw-light my-4">Set New Password </h3>
                      </div>
                      <div className="card-body">
                        <div className="small mb-3 text-muted">Enter the verification code sent to your email along with your new password.</div>
                        {/* Forgot password form*/}
                        <form onSubmit={onResetPressed}>
                          {/* Form Group (email address)*/}
                          <div className="mb-3">
                            <label className="small mb-1" htmlFor="inputVerificationCode">
                              Verification Code
                            </label>
                            <div className="input-group">
                              <input
                                value={pin.value}
                                onChange={(event) => {
                                  const re = /^[0-9\b]+$/;
                                  if (event.target.value === "" || re.test(event.target.value)) {
                                    setPin({ value: event.target.value, error: "" });
                                  }
                                }}
                                maxLength={6}
                                className="form-control"
                                id="inputVerificationCode"
                                type={showPin ? "text" : "password"}
                                placeholder="Enter verification code"
                              />
                              <span
                                className="input-group-text"
                                onClick={() => setShowPin((prev) => !prev)}
                                aria-label={showPin ? "Hide password" : "Show password"}
                                style={{ cursor: "pointer" }} // Makes the icon clickable
                              >
                                {showPin ? <FaEyeSlash /> : <FaEye />}
                              </span>
                            </div>
                          </div>
                          {pin.error.length > 0 && <div className="tiny alert alert-danger">{pin.error}</div>}
                          <div className="mb-3">
                            <label className="small mb-1" htmlFor="inputPassword">
                              New Password
                            </label>
                            <div className="input-group">
                              <input
                                value={password.value}
                                onChange={(event) => setPassword({ value: event.target.value.replace(/\s/g, ""), error: "" })}
                                className="form-control"
                                id="inputPassword"
                                type={showNewPassword ? "text" : "password"}
                                placeholder="Enter new password"
                              />
                              <span
                                className="input-group-text"
                                onClick={() => setShowNewPassword((prev) => !prev)}
                                aria-label={showNewPassword ? "Hide password" : "Show password"}
                                style={{ cursor: "pointer" }} // Makes the icon clickable
                              >
                                {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                              </span>
                            </div>
                          </div>

                          <div className="mb-3">
                            <label className="small mb-1" htmlFor="inputPassword">
                              Confirm Password
                            </label>
                            <div className="input-group">
                              <input
                                value={confirmPassword.value}
                                onChange={(event) => setConfirmPassword({ value: event.target.value.replace(/\s/g, ""), error: "" })}
                                className="form-control"
                                id="inputPassword"
                                type={showConfirmPassword ? "text" : "password"}
                                placeholder="Confirm password"
                              />
                              <span
                                className="input-group-text"
                                onClick={() => setShowConfirmPassword((prev) => !prev)}
                                aria-label={showConfirmPassword ? "Hide password" : "Show password"}
                                style={{ cursor: "pointer" }} // Makes the icon clickable
                              >
                                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                              </span>
                            </div>
                          </div>

                          <div>
                            {password.error.length > 0 && <div className="tiny alert alert-danger">{password.error}</div>}
                            {password.error.length === 0 && confirmPassword.error.length > 0 && (
                              <div className="tiny alert alert-danger">{confirmPassword.error}</div>
                            )}
                          </div>

                          {/* Form Group (submit options)*/}
                          <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                            <div
                              className="small link-primary"
                              aria-disabled={loading}
                              onClick={(event) => {
                                if (!loading) {
                                  onReturnPressed(event);
                                }
                              }}
                            >
                              Return to login
                            </div>
                            <div
                              // className="btn btn-primary"
                              className={`btn btn-primary ${loading ? "disabled" : ""}`}
                              aria-disabled={loading}
                              onClick={(event) => {
                                if (!loading) {
                                  onResetPressed(event);
                                }
                              }}
                            >
                              Reset Password
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="card-footer text-center"></div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
          <div id="layoutAuthentication_footer">
            <Footer light={false} />
          </div>
        </div>
      </div>
    </>
  );
}
