import Navbar from "../components/NavBars/Navbar";
import React, { useEffect, useState } from "react";
import SideNavbar from "../components/NavBars/SideNavbar";
import SettingsNavbar from "../components/NavBars/SettingsNavbar";
import Footer from "../components/Footer";
import SettingsHeader from "../components/Headers/SettingsHeader";
import { passwordValidator } from "../utils/validators/passwordValidator";
import { confirmPasswordValidator } from "../utils/validators/confirmPasswordValidator";
import Global from "../utils/Global";
import auth_errors from "../config/auth_errors";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons

export default function Security() {
  const [oldPassword, setOldPassword] = useState({ value: "", error: "" });
  const [newPassword, setNewPassword] = useState({ value: "", error: "" });
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState({ value: "", error: "" });
  const [updatingPassword, setUpdatingPassword] = useState(false);
  const [passChanged, setPassChanged] = useState(false);

  useEffect(() => {
    document.body.classList.add("nav-fixed");

    return () => {
      document.body.classList.remove("nav-fixed");
    };
  }, []);

  const onChangePasswordSubmit = async (event) => {
    event.preventDefault();

    setUpdatingPassword(true);
    setPassChanged(false);
    const oldPasswordError = passwordValidator(oldPassword.value);
    const newPasswordError = passwordValidator(newPassword.value);
    const confirmPasswordError = confirmPasswordValidator(newPassword.value, confirmPassword.value);
    if (oldPasswordError || newPasswordError || confirmPasswordError) {
      setOldPassword({ ...oldPassword, error: oldPasswordError });
      setNewPassword({ ...newPassword, error: newPasswordError });
      setConfirmPassword({ ...confirmPassword, error: confirmPasswordError });
      setUpdatingPassword(false);
      return;
    }

    if (updatingPassword) {
      return;
    }
    setUpdatingPassword(true);

    Global.localAppHandler
      .app_handle_change_password(oldPassword.value, newPassword.value)
      .then((returnStatus) => {
        setPassChanged(true);
        setOldPassword({ value: "", error: "" });
        setNewPassword({ value: "", error: "" });
        setConfirmPassword({ value: "", error: "" });
      })
      .catch((auth_error) => {
        if (auth_error === auth_errors.incorrect_user_pass) {
          setOldPassword({ ...oldPassword, error: "Old password is incorrect, please try again." });
        } else if (auth_error === auth_errors.too_many_attempts) {
          setOldPassword({ ...oldPassword, error: "Attempt limit exceeded, please try after some time." });
        } else {
          setOldPassword({ ...oldPassword, error: "There was an error, please try again." });
        }
      })
      .finally(() => {
        setUpdatingPassword(false);
      });
  };
  return (
    <>
      <Navbar />
      <div id="layoutSidenav">
        <SideNavbar
          patientSubHeaderEnabled={false}
          activeTab={{ studyOverview: false, subjects: false, manageTeam: false, reviewReports: false, remoteAlerts: false }}
        />
        <div id="layoutSidenav_content">
          <main>
            <SettingsHeader title="Account Settings - Security" />
            {/* Main page content*/}
            <div className="container-xl px-4 mt-4">
              {/* Account page navigation*/}
              <SettingsNavbar activeTab={{ account: false, billing: false, security: true }} />
              <hr className="mt-0 mb-4" />
              <div className="row">
                <div className="col-lg-8">
                  {/* Change password card*/}
                  <div className="card mb-4">
                    <div className="card-header">Change Password</div>
                    <div className="card-body">
                      <form onSubmit={onChangePasswordSubmit}>
                        {/* Form Group (current password)*/}
                        {passChanged && <div className="tiny alert alert-success">Password successfully changed!</div>}
                        <div className="mb-3">
                          <label className="small mb-1" htmlFor="currentPassword">
                            Current Password
                          </label>
                          <div className="input-group">
                            <input
                              value={oldPassword.value}
                              onChange={(event) => setOldPassword({ value: event.target.value.replace(/\s/g, ""), error: "" })}
                              className={oldPassword.error.length > 0 ? "form-control mb-2" : "form-control"}
                              name="currentPassword"
                              id="currentPassword"
                              type={showCurrentPassword ? "text" : "password"}
                              placeholder="Enter current password"
                            />
                            <span
                              className="input-group-text"
                              onClick={() => setShowCurrentPassword((prev) => !prev)}
                              aria-label={showCurrentPassword ? "Hide password" : "Show password"}
                              style={{ cursor: "pointer" }} // Makes the icon clickable
                            >
                              {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                          </div>

                          {oldPassword.error.length > 0 && <div className="tiny alert alert-danger">{oldPassword.error}</div>}
                        </div>
                        {/* Form Group (new password)*/}
                        <div className="mb-3">
                          <label className="small mb-1" htmlFor="newPassword">
                            New Password
                          </label>
                          <div className="input-group">
                            <input
                              value={newPassword.value}
                              onChange={(event) => setNewPassword({ value: event.target.value.replace(/\s/g, ""), error: "" })}
                              className={newPassword.error.length > 0 ? "form-control mb-2" : "form-control"}
                              name="newPassword"
                              id="newPassword"
                              type={showNewPassword ? "text" : "password"}
                              placeholder="Enter new password"
                            />
                            <span
                              className="input-group-text"
                              onClick={() => setShowNewPassword((prev) => !prev)}
                              aria-label={showNewPassword ? "Hide password" : "Show password"}
                              style={{ cursor: "pointer" }} // Makes the icon clickable
                            >
                              {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                          </div>
                          {newPassword.error.length > 0 && <div className="tiny alert alert-danger">{newPassword.error}</div>}
                        </div>
                        {/* Form Group (confirm password)*/}
                        <div className="mb-3">
                          <label className="small mb-1" htmlFor="confirmPassword">
                            Confirm Password
                          </label>
                          <div className="input-group">
                            <input
                              value={confirmPassword.value}
                              onChange={(event) => setConfirmPassword({ value: event.target.value.replace(/\s/g, ""), error: "" })}
                              className={confirmPassword.error.length > 0 ? "form-control mb-2" : "form-control"}
                              name="confirmPassword"
                              id="confirmPassword"
                              type={showConfirmPassword ? "text" : "password"}
                              placeholder="Confirm new password"
                            />
                            <span
                              className="input-group-text"
                              onClick={() => setShowConfirmPassword((prev) => !prev)}
                              aria-label={showConfirmPassword ? "Hide password" : "Show password"}
                              style={{ cursor: "pointer" }} // Makes the icon clickable
                            >
                              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                          </div>

                          {confirmPassword.error.length > 0 && <div className="tiny alert alert-danger">{confirmPassword.error}</div>}
                        </div>
                        <button className={`btn btn-primary ${updatingPassword ? "disabled" : ""}`} type="submit" disabled={updatingPassword}>
                          Save
                        </button>
                      </form>
                    </div>
                  </div>
                  {/* Security preferences card*/}
                  {/* <div className="card mb-4">
                    <div className="card-header">Security Preferences</div>
                    <div className="card-body"> */}
                  {/* Account privacy optinos*/}
                  {/* <h5 className="mb-1">Account Privacy</h5>
                      <p className="small text-muted">
                        By setting your account to private, your profile information and posts will not be visible to users outside of your user groups.
                      </p>
                      <form>
                        <div className="form-check">
                          <input className="form-check-input" id="radioPrivacy1" type="radio" name="radioPrivacy" defaultChecked />
                          <label className="form-check-label" htmlFor="radioPrivacy1">
                            Public (posts are available to all users)
                          </label>
                        </div>
                        <div className="form-check">
                          <input className="form-check-input" id="radioPrivacy2" type="radio" name="radioPrivacy" />
                          <label className="form-check-label" htmlFor="radioPrivacy2">
                            Private (posts are available to only users in your groups)
                          </label>
                        </div>
                      </form>
                      <hr className="my-4" /> */}
                  {/* Data sharing options*/}
                  {/* <h5 className="mb-1">Data Sharing</h5>
                      <p className="small text-muted">
                        Sharing usage data can help us to improve our products and better serve our users as they navigation through our application. When you
                        agree to share usage data with us, crash reports and usage analytics will be automatically sent to our development team for
                        investigation.
                      </p>
                      <form>
                        <div className="form-check">
                          <input className="form-check-input" id="radioUsage1" type="radio" name="radioUsage" defaultChecked />
                          <label className="form-check-label" htmlFor="radioUsage1">
                            Yes, share data and crash reports with app developers
                          </label>
                        </div>
                        <div className="form-check">
                          <input className="form-check-input" id="radioUsage2" type="radio" name="radioUsage" />
                          <label className="form-check-label" htmlFor="radioUsage2">
                            No, limit my data sharing with app developers
                          </label>
                        </div>
                      </form>
                    </div>
                  </div> */}
                </div>
                <div className="col-lg-4">
                  {/* Two factor authentication card*/}
                  {/* <div className="card mb-4">
                    <div className="card-header">Two-Factor Authentication</div>
                    <div className="card-body">
                      <p>
                        Add another level of security to your account by enabling two-factor authentication. We will send you a text message to verify your
                        login attempts on unrecognized devices and browsers.
                      </p>
                      <form>
                        <div className="form-check">
                          <input className="form-check-input" id="twoFactorOn" type="radio" name="twoFactor" defaultChecked />
                          <label className="form-check-label" htmlFor="twoFactorOn">
                            On
                          </label>
                        </div>
                        <div className="form-check">
                          <input className="form-check-input" id="twoFactorOff" type="radio" name="twoFactor" />
                          <label className="form-check-label" htmlFor="twoFactorOff">
                            Off
                          </label>
                        </div>
                        <div className="mt-3">
                          <label className="small mb-1" htmlFor="twoFactorSMS">
                            SMS Number
                          </label>
                          <input className="form-control" id="twoFactorSMS" type="tel" placeholder="Enter a phone number" defaultValue="555-123-4567" />
                        </div>
                      </form>
                    </div>
                  </div> */}
                  {/* Delete account card*/}
                  {/* <div className="card mb-4">
                    <div className="card-header">Delete Account</div>
                    <div className="card-body">
                      <p>
                        Deleting your account is a permanent action and cannot be undone. If you are sure you want to delete your account, select the button
                        below.
                      </p>
                      <button className="btn btn-danger-soft text-danger" type="button">
                        I understand, delete my account
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </main>
          <Footer light={true} />
        </div>
      </div>
    </>
  );
}
