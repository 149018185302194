export function passwordValidator(password) {
  const reCapital = /[A-Z]/;
  const reLower = /[a-z]/;
  const reSpecial = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
  const reNumber = /[0-9]/; // Check for numeric digit

  if (!password) return "Password can't be empty.";
  if (password.length < 8) return "Password must be at least 8 characters long.";
  if (!reCapital.test(password)) return "Password must have uppercase characters.";
  if (!reLower.test(password)) return "Password must have lowercase characters.";
  if (!reSpecial.test(password)) return "Password must have special characters.";
  if (!reNumber.test(password)) return 'Password must have at least one number.';
  return "";
}
