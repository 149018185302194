import React, { useState, useEffect, useMemo } from "react";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import { MoreVertical, Trash2, CheckCircle, XCircle } from "react-feather";
import { TiUserAdd, TiUserDelete } from "react-icons/ti";
import { GlobalFilter } from "../GlobalFilter";
import { PlusCircle } from "react-feather";
import AddTeamModal from "../Modals/AddTeamModal";
import account_status from "../../config/account_status";
import DeactivateModal from "../Modals/DeactivateModal";
import Global from "../../utils/Global";
import Team from "../../utils/Team";
import ActivateModal from "../Modals/ActivateModal";
import User from "../../utils/User";
import account_types from "../../config/account_types";
import MakeGeneralModal from "../Modals/MakeGeneralModal";
import MakeAdminModal from "../Modals/MakeAdminModal";

export function ManageTable({ team, isLoading, refreshTeam, ...props }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);
  const [isActivateModalOpen, setIsActivateModalOpen] = useState(false);
  const [isMakeAdminModalOpen, setIsMakeAdminModalOpen] = useState(false);
  const [isMakeGeneralModalOpen, setIsMakeGeneralModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const teamsData = useMemo(() => (team ? [...team] : []), [team]);
  const loggedInUser = teamsData.find((user) => user.name === Global.localAppHandler.user.name);

  const teamsColumns = useMemo(
    () => [
      {
        Header: "User",
        accessor: "name",
      },
      {
        Header: "Type",
        accessor: "account_type",
        Cell: ({ value }) => value,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => <span style={{ color: getStatusColor(value) }}>{value}</span>,
      },
      {
        id: "Actions",
        Header: "Actions",
        accessor: (row) => "",
        Cell: ({ row }) => {
          if (row.original.name === loggedInUser.name || loggedInUser.account_type === account_types.general) {
            return null;
          }

          const status = row.original.status;
          const accountType = row.original.account_type;

          let activationButton;
          switch (status) {
            case account_status.active:
              activationButton = (
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => {
                    handleDeactivate(row.original);
                  }}
                >
                  Deactivate
                </button>
              );
              break;
            case account_status.inactive:
              activationButton = (
                <button className="btn btn-sm btn-primary" onClick={() => handleActivate(row.original)}>
                  Activate
                </button>
              );
              break;
            case account_status.pending:
              break;
            case account_status.deleted:
              break;
          }

          let accountTypeButton;
          switch (accountType) {
            case account_types.general:
              accountTypeButton = (
                <button
                  className="btn btn-sm btn-success"
                  onClick={() => {
                    handleMakeAdmin(row.original);
                  }}
                >
                  Promote to Admin
                </button>
              );
              break;
            case account_types.admin:
              accountTypeButton = (
                <button className="btn btn-sm btn-warning" onClick={() => handleMakeGeneral(row.original)}>
                  Demote to General
                </button>
              );
              break;
          }

          return (
            <div className="d-flex gap-2">
              {activationButton}
              {accountTypeButton}
            </div>
          );
        },
      },
    ],
    [team]
  );

  const handleActivate = (user) => {
    setSelectedUser(user);
    setIsActivateModalOpen(true);
  };

  const handleDeactivate = (user) => {
    setSelectedUser(user);
    setIsDeactivateModalOpen(true);
  };

  const handleMakeAdmin = (user) => {
    setSelectedUser(user);
    setIsMakeAdminModalOpen(true);
  };

  const handleMakeGeneral = (user) => {
    setSelectedUser(user);
    setIsMakeGeneralModalOpen(true);
  };

  // const getStatusString = (status) => {
  //   switch (status) {
  //     case account_status.active:
  //       return "Active";
  //     case account_status.inactive:
  //       return "Inactive";
  //     case account_status.pending:
  //       return "Pending";
  //     default:
  //       return "Unknown";
  //   }
  // };

  const getStatusColor = (status) => {
    switch (status) {
      case account_status.active:
        return "green";
      case account_status.inactive:
        return "red";
      case account_status.deleted:
        return "red";
      case account_status.pending:
        return "orange";
      default:
        return "black";
    }
  };

  const tableInstance = useTable({ columns: teamsColumns, data: teamsData }, useGlobalFilter, useSortBy); // tableHooks

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, preGlobalFilteredRows, setGlobalFilter, state } = tableInstance;

  return (
    <>
      <AddTeamModal
        isOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen(false);
        }}
        handleSave={() => {
          refreshTeam();
          setIsModalOpen(false);
        }}
      />

      <DeactivateModal
        isOpen={isDeactivateModalOpen}
        selectedUser={selectedUser}
        handleClose={() => {
          setIsDeactivateModalOpen(false);
        }}
        handleSave={() => {
          refreshTeam();
          setIsDeactivateModalOpen(false);
        }}
      />

      <ActivateModal
        isOpen={isActivateModalOpen}
        selectedUser={selectedUser}
        handleClose={() => {
          setIsActivateModalOpen(false);
        }}
        handleSave={() => {
          refreshTeam();
          setIsActivateModalOpen(false);
        }}
      />

      <MakeGeneralModal
        isOpen={isMakeGeneralModalOpen}
        selectedUser={selectedUser}
        handleClose={() => {
          setIsMakeGeneralModalOpen(false);
        }}
        handleSave={() => {
          refreshTeam();
          setIsMakeGeneralModalOpen(false);
        }}
      />
      <MakeAdminModal
        isOpen={isMakeAdminModalOpen}
        selectedUser={selectedUser}
        handleClose={() => {
          setIsMakeAdminModalOpen(false);
        }}
        handleSave={() => {
          refreshTeam();
          setIsMakeAdminModalOpen(false);
        }}
      />

      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px" }}>
        {/* Plus button */}
        <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} setGlobalFilter={setGlobalFilter} globalFilter={state.globalFilter} />
        {loggedInUser?.account_type === account_types.admin ? (
          <button
            onClick={() => {
              setIsModalOpen(true);
            }}
            disabled={loggedInUser ? false : true}
            className={`btn btn-primary me-2 ${loggedInUser ? "" : "btn-disabled"}`}
          >
            <PlusCircle size={16} style={{ marginRight: "5px" }} />
            Add Member
          </button>
        ) : (
          ""
        )}
      </div>
      <table className="table table-bordered" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <img src={require("../../images/down_arrow.png")} alt="" />
                    ) : (
                      <img src={require("../../images/up_arrow.png")} alt="" />
                    )
                  ) : column.Header === "Actions" ? (
                    ""
                  ) : (
                    <img src={require("../../images/default.png")} alt="" />
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, idx) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} style={{ backgroundColor: row.original.name === Global.localAppHandler.user.name ? "#d3d3d3" : "inherit" }}>
                {row.cells.map((cell, idx) => (
                  <td className="" {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
