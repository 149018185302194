import Trial from "./Trial";
import Report from "./Report";

export default class Period {
  constructor({
    subject_id,
    period_id,
    start_date,
    end_date,
    period_length = 30,
    days_collected,
    days_remaining,
    trials = null,
    report = null,
    goal,
  }) {
    this._subject_id = subject_id;
    this._period_id = period_id;
    this._start_date = start_date;
    this._end_date = end_date;
    this.period_length = period_length;
    this._days_collected = days_collected;
    this._days_remaining = days_remaining;
    this._trials = trials;
    this._report = report;
    this._goal = goal;
  }

  static createFromAPI(period_api) {
    return new Period({
      subject_id: period_api ? period_api.subject_id : null,
      period_id: period_api ? period_api.period_id : null,
      start_date: period_api ? period_api.start_date : null,
      end_date: period_api ? period_api.end_date : null,
      days_collected: period_api ? period_api.days_collected : null,
      days_remaining: period_api ? period_api.days_remaining : null,
      trials: period_api && period_api.trials ? period_api.trials.map(trial => Trial.createFromAPI(trial)) : null,
      report: period_api && period_api.report ? Report.createFromAPI(period_api.report) : null,
      goal: period_api ? period_api.goal : null,
    });
  }

  calculateDaysRemaining() {
    const endDate = new Date(this.end_date);
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const diffMilliseconds = endDate - now;
    const daysRemaining = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));
    return daysRemaining;
  }

  get subject_id() {
    return this._subject_id;
  }
  set subject_id(subject_id) {
    this._subject_id = subject_id;
  }

  get period_id() {
    return this._period_id;
  }
  set period_id(period_id) {
    this._period_id = period_id;
  }

  get start_date() {
    return this._start_date;
  }
  set start_date(start_date) {
    this._start_date = start_date;
  }

  get end_date() {
    return this._end_date;
  }
  set end_date(end_date) {
    this._end_date = end_date;
  }

  get period_length() {
    return this._period_length;
  }
  set period_length(period_length) {
    this._period_length = period_length;
  }

  get days_collected() {
    return this._days_collected;
  }
  set days_collected(days_collected) {
    this._days_collected = days_collected;
  }

  get days_remaining() {
    return this._days_remaining;
  }
  set days_remaining(days_remaining) {
    this._days_remaining = days_remaining;
  }

  get trials() {
    return this._trials;
  }
  set trials(trials) {
    this._trials = trials;
  }

  get report() {
    return this._report;
  }
  set report(report) {
    this._report = report;
  }

  get goal() {
    return this._goal;
  }
  set goal(goal) {
    this._goal = goal;
  }
}
