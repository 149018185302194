
// never meant to make an actual instance of this class, "abstract" class but javascript is strange
export default class BaseWrapper {
  constructor({ attribute_name, value_validator, error }) {
    this._attribute_name = attribute_name;
    this._value_validator = value_validator;
    this._error = error;
  }

  get error() {
    return this._error;
  }
  set error(error) {
    this._error = error;
  }

  get value_validator() {
    return this._value_validator;
  }
  set value_validator(value_validator) {
    this._value_validator = value_validator;
  }

  get attribute_name() {
    return this._attribute_name;
  }
  set attribute_name(attribute_name) {
    this._attribute_name = attribute_name;
  }

  //exists in derived class
  get value() {
    return this._value;
  }

  // exists in derived class
  set value(value) {
    this._value = value;
  }
}
