import React, { useState } from "react";
import treated_side from "../../config/treated_side";
import DateInput from "../Inputs/DateInput";
import { dateValidator } from "../../utils/validators/dateValidator";

const PrimaryConditionModal = ({dateOfInjury, setDateOfInjury, isNASelected, setIsNASelected, isOpen, handleClose, handleSave, selectedOption }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              Selected Option:{" "}
            </h5>
            <button
              className="btn-close"
              type="button"
              aria-label="Close"
              onClick={() => {
                setDateOfInjury({
                  value: "",
                  error: "",
                });
                setIsNASelected(false);
                handleClose();
              }}
            ></button>
          </div>
          <div className="modal-body">
            <p>{selectedOption}</p>
            <div className="row gx-3 align-items-start">
              <div className="col-md-6">
                <DateInput
                  disabled={isNASelected}
                  value={dateOfInjury.value}
                  onChange={(date) => {
                    setDateOfInjury({ value: date, error: "" });
                  }}
                  errorText={dateOfInjury.error}
                  description="Date of Injury"
                />
              </div>
              </div>
              <div className="row gx-3 mb-3 align-items-start">

              <div className="col-md-3 ">
                <div className="form-check mt-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="naCheckbox"
                    checked={isNASelected}
                    onChange={() => {
                      setDateOfInjury({
                        value: "",
                        error: "",
                      });
                      setIsNASelected(!isNASelected);
                    }}
                  />
                  <label className="form-check-label" htmlFor="naCheckbox">
                    N/A
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => {
                setDateOfInjury({
                  value: "",
                  error: "",
                });
                setIsNASelected(false);
                handleClose();
              }}
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => {
                const dateError = dateValidator(dateOfInjury.value);

                if (!isNASelected && dateError) {
                  setDateOfInjury({
                    ...dateOfInjury,
                    error: dateError,
                  });
                  return;
                }
                handleSave(dateOfInjury.value);
                setDateOfInjury({
                  value: "",
                  error: "",
                });
                setIsNASelected(false);
              }}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrimaryConditionModal;
{
  /* <div className="modal fade" id="exampleModalCenter" tabindex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
<div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
        <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">Selected Option: </h5>
            <p>{selectedOption}</p>
            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
        </div>
        <div className="modal-body">...</div>
        <div className="modal-footer"><button class="btn btn-secondary" type="button" data-bs-dismiss="modal">Close</button><button class="btn btn-primary" type="button">Save changes</button></div>
    </div>
</div>
</div> */
}
