import patch_options from "../../config/patch_options";
import Field from "./Field";
import BaseWrapper from "./BaseWrapper";
import Patch from "./Patch";

export default class FieldWrapperArray extends BaseWrapper {
  constructor({attribute_name, value, error, value_validator}) {//, modified = 'none' }) {
    super({attribute_name: attribute_name, value_validator: value_validator, error: error })
    this._value = value ? value.map((val) => val instanceof Field ? val : new Field({ value: val })) : null;  }

  handleInputChangeListAdd(newVal) {
    if (this.value_validator) {
      newVal = this.value_validator(newVal);
    }
    if (this.value) {
      let index = this.value.findIndex((item) => this.isEqual(item.value, newVal));
      if (index > -1) {
        this.value[index].value = newVal; 
      } else {
        this.value.push(new Field({ value: newVal })
          );
      }
    } else {
      this.value = [new Field({ value: newVal })];
    }
    this.error = "";
  }

  handleInputChangeListReplace(val) {
    if (this.value) {
      let index = this.value.findIndex((item) => this.isEqual(item.value, val));
      if (index > -1) {
        this.value[index].value = val; 
      }
    }
    this.error = "";
  }

  handleInputChangeListRemove(val) {
    if (this.value) {
      this.value = this.value.filter((o) => o.value !== val);
    }
    this.error = "";
  }

  isEqual(val1, val2) {
    if (typeof val1 === 'object' && typeof val2 === 'object') {
      return val1.equals(val2); // if an object use custom equality
    }
    return val1 === val2; // if a primitive use strict equality
  }

  getUI() {
    return this.value ? this.value.map((val) => val.value): null;
  }

}
