import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

export default function PatientNavbar(props) {
  let navigate = useNavigate();
  const location = useLocation();

  const onOverviewClicked = async (event) => {
    event.preventDefault();
    navigate(location.pathname.slice(0, location.pathname.lastIndexOf("/")) + "/overview");
  };
  const onTrialsClicked = async (event) => {
    event.preventDefault();
    // navigate(location.pathname.slice(0, location.pathname.lastIndexOf("/")) + "/trials");
  };
  const onDetailedClicked = async (event) => {
    event.preventDefault();
    // navigate(location.pathname.slice(0, location.pathname.lastIndexOf("/")) + "/detailed");
  };
  const onGaitMetricsClicked = async (event) => {
    event.preventDefault();
    navigate(location.pathname.slice(0, location.pathname.lastIndexOf("/")) + "/metrics");
  };

  const onReportsClicked = async (event) => {
    event.preventDefault();
    navigate(location.pathname.slice(0, location.pathname.lastIndexOf("/")) + "/reports");
  };

  return (
    <nav className="nav nav-borders">
      <div className={"nav-link ms-0" + (props.activeTab.overview ? " active" : "")} onClick={onOverviewClicked}>
        Overview
      </div>
      {/* <div className={"nav-link" + (props.activeTab.trials ? " active" : "")} onClick={onTrialsClicked}>
        Trials
      </div> */}
      {/* <div className={"nav-link" + (props.activeTab.detailed ? " active" : "")} onClick={onDetailedClicked}>
        Detailed View
      </div> */}
      <div className={"nav-link" + (props.activeTab.gait_metrics ? " active" : "")} onClick={onGaitMetricsClicked}>
        Gait Metrics
      </div>
      <div className={"nav-link" + (props.activeTab.reports ? " active" : "")} onClick={onReportsClicked}>
        Reports
      </div>
    </nav>
  );
}
