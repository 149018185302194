import BaseWrapper from "./BaseWrapper";
import Patch from "./Patch";
import patch_options from "../../config/patch_options";

export default class PatchWrapperSingle extends BaseWrapper{
  constructor({attribute_name, value, error, value_validator}) {//, modified = 'none' }) {
    super({attribute_name: attribute_name, value_validator: value_validator, error: error })
    this._value = value instanceof Patch ? value : new Patch({ value: value, original_value: value, op: patch_options.none });//, modified);
  }

  handleInputChange(newVal) {
    if (this.value_validator) {
      newVal = this.value_validator(newVal);
    }
    this.error = "";
    this.value.handlePatch(newVal, patch_options.replace);
  }

  getUI() {
    return this.value.value;
  }

}
