import React, { useState, useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import { useNavigate } from 'react-router-dom';
import Global from "../../utils/Global";
import { ChevronUp, ChevronDown } from 'react-feather';

const DropdownTable = ({ title, data, startOpen = false, report = null }) => {
  const [open, setOpen] = useState(startOpen);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null); // State for tracking hovered row index
  const navigate = useNavigate();

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleRowClick = (trial_id) => {
    const newPath = window.location.pathname.slice(0, window.location.pathname.lastIndexOf("/")) + "/trials/" + trial_id;
    
    Global.localAppHandler.app_set_active_trial(trial_id).then(() => {
      navigate(newPath);
    }).catch(() => {
      console.log("this should never happen");
    });
  };

  const handleDownload = (e) => {
    e.stopPropagation();
    Global.localAppHandler.app_get_report(Global.localAppHandler.active_subject, report.report_id);
  };

  // Define columns for react-table
  const columns = useMemo(() => [
    {
      Header: 'Trial Name',
      accessor: 'trial_name',
    },
    {
      Header: 'Trial Date',
      accessor: 'trial_date',
      sortType: (rowA, rowB) => {
        const dateA = new Date(rowA.original.trial_date);
        const dateB = new Date(rowB.original.trial_date);
        return dateA - dateB;
      },
    },
  ], []);

  // Initialize react-table instance
  const tableInstance = useTable(
    { columns, data },
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <div className="mb-4 border rounded bg-white px-3">
      <div
        className="d-flex justify-content-between align-items-center p-3 bg-white"
        onClick={handleToggle}
        style={{ cursor: 'pointer' }}
      >
        <span className="font-weight-bold h5">{title}</span>
        <div className="d-flex align-items-center">
          {report && report.is_ready ? (
            <a href="#" className="mr-3 btn btn-link p-0" onClick={handleDownload}>
              Download Report
            </a>
          ) : (
            <div className="mr-3 btn p-0">Report Not Ready</div>
          )}
          {open ? <ChevronUp/> : <ChevronDown/>}
        </div>
      </div>
      {open && (
        <table className="table table-bordered bg-white" {...getTableProps()}>
          <thead className="thead-light">
            {headerGroups.map((headerGroup, headerGroupIdx) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIdx}>
                {headerGroup.headers.map((column, columnIdx) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} key={columnIdx}>
                    {column.render('Header')}
                    {!column.disableSortBy && column.isSorted ? (
                      column.isSortedDesc ? (
                        <img src={require("../../images/down_arrow.png")} alt="Down Arrow" />
                      ) : (
                        <img src={require("../../images/up_arrow.png")} alt="Up Arrow" />
                      )
                    ) : (
                      !column.disableSortBy && <img src={require("../../images/default.png")} alt="Default Arrow" />
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row, rowIdx) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    key={rowIdx}
                    onMouseEnter={() => setHoveredRowIndex(rowIdx)} // Set hovered row index on mouse enter
                    onMouseLeave={() => setHoveredRowIndex(null)} // Clear hovered row index on mouse leave
                    onClick={() => handleRowClick(row.original.trial_id)}
                    style={{ cursor: 'pointer' }}
                  >
                    {row.cells.map((cell, cellIdx) => (
                      <td
                        {...cell.getCellProps()}
                        key={cellIdx}
                        className={hoveredRowIndex === rowIdx ? "text-primary" : ""} // Apply "text-primary" class on hover
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={columns.length}>No Trials Recorded</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default DropdownTable;
