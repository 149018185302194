import Field from "./Field";
import BaseWrapper from "./BaseWrapper";
import Patch from "./Patch";
import patch_options from "../../config/patch_options";

export default class FieldWrapperSingle extends BaseWrapper{
  constructor({attribute_name, value, error, value_validator}) {//, modified = 'none' }) {
    super({attribute_name: attribute_name, value_validator: value_validator, error: error })
    this._value = value instanceof Field ? value : new Field({ value: value });//, modified);
  }

  handleInputChange(newVal) {
    if (this.value_validator) {
      newVal = this.value_validator(newVal);
    }
    this.error = "";
    this.value = new Field({ value: newVal });
  }

  getUI() {
    return this.value.value;
  }

}
