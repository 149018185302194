import React from "react";
import { List } from "react-feather";

export default function MainHeader(props) {
  return (
    <header className="page-header page-header-dark bg-primary pb-10">
      <div className="container-xl px-4">
        <div className="page-header-content pt-4">
          <div className="row align-items-center justify-content-between">
            <div className="col-auto mt-4">
              <h1 className="page-header-title">
                <div className="page-header-icon">
                  <List />
                </div>
                {props.title}
              </h1>
              <div className="page-header-subtitle" />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
