import React, { useState, useEffect, useMemo } from "react";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import { PlusCircle, RefreshCw } from "react-feather";
import { GlobalFilter } from "../GlobalFilter";
import { useNavigate } from "react-router-dom";
import Global from "../../utils/Global";
import Subject from "../../utils/Subject";
import RefreshButton from "../RefreshButton";
import patient_account_types from "../../config/patient_account_types";
import account_status from "../../config/account_status";
import ReactSwitch from "react-switch";
import display_types from "../../config/display_types";

export function ReviewReportsTable({ subjectsLoading, setSubjectsLoading, reports, displayType, ...props }) {
  const navigate = useNavigate();
  const [filter, setFilter] = useState("");
  const [activeFilter, setActiveFilter] = useState(null);
  const [reportsState, setReportsState] = useState(reports);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  useEffect(() => {
    setReportsState(reports);
  }, [reports]);

  const onRowClick = (cell) => {
    const subject = cell.row.original;
    Global.localAppHandler
      .app_set_subject(subject)
      .then((subject_id) => {
        navigate("/dashboard/" + subject_id + "/overview");
      })
      .catch((returnStatus) => {
        console.log("this should never happen");
      });

    // }
  };

  const onDownloadPress = (row) => {
    const subject_id = row.original.subject_id;
    const report_id = row.original.download_link;
    Global.localAppHandler.app_get_report(subject_id, report_id);
  };

  const toggleFilter = (newFilter) => {
    const isCurrentlyActive = activeFilter === newFilter;
    setFilter(isCurrentlyActive ? "" : newFilter); // Clear or apply new filter
    setActiveFilter(isCurrentlyActive ? null : newFilter); // Set active filter for UI feedback
  };

  const handleToggle = (subject) => {
    console.log(subject);
    const subject_id = subject.subject_id;
    const report_id = subject.download_link;
    const new_report_reviewed_status = !subject.report_reviewed;

    Global.localAppHandler
      .app_set_report_reviewed(subject_id, report_id, new_report_reviewed_status)
      .then((returnStatus) => {
        setReportsState((prevReports) =>
          prevReports.map((report) => (report.subject_id === subject_id ? { ...report, report_reviewed: !report.report_reviewed } : report))
        );
      })
      .catch((returnStatus) => {
        console.log("err setting report reviewed status");
      });

    // Optionally, you can also update the backend here
  };

  const initialState = {
    sortBy: [
      {
        id: "subject_id", // the accessor of the column you want to sort by
        desc: false, // false for ascending, true for descending
      },
    ],
  };

  const patientsData = useMemo(() => {
    if (!filter) return reportsState; // No filter applied
    return reportsState.filter((report) => {
      if (filter === "Show Reviewed Reports") {
        return report.report_reviewed;
      } else if (filter === "Hide Reviewed Reports") {
        return !report.report_reviewed;
      } else {
        return false;
      }
    });
  }, [reportsState, filter]);

  const patientsColumns = useMemo(
    () =>
      reportsState && reportsState[0]
        ? [
            ...(displayType === display_types.PHI
              ? [
                  {
                    Header: "First Name",
                    accessor: "_first_name",
                  },
                  {
                    Header: "Last Name",
                    accessor: "_last_name",
                  },
                  {
                    Header: "Date of Birth",
                    accessor: "_dob",
                    sortType: (rowA, rowB) => {
                      const dateA = new Date(rowA.values._dob);
                      const dateB = new Date(rowB.values._dob);
                      return dateA - dateB;
                    },
                  },
                ]
              : [
                  {
                    Header: "Subject ID",
                    accessor: "_name",
                  },
                ]),
            {
              Header: "Remote Monitoring Period",
              accessor: (row) => row.start_date + " - " + row.end_date,
              id: "remote_monitoring_period",
              sortType: (rowA, rowB) => {
                const dateA = new Date(rowA.original.start_date);
                const dateB = new Date(rowB.original.start_date);
                return dateA - dateB;
              },
            },
            {
              Header: "View Report",
              accessor: "download_link",
              disableSortBy: true,
              Cell: ({ row }) => (
                <button
                  onClick={(event) => {
                    event.stopPropagation(); // Prevent row click
                    onDownloadPress(row);
                  }}
                  style={{
                    background: "none",
                    border: "none",
                    color: "#39a4db",
                    cursor: "pointer",
                    padding: 0,
                  }}
                >
                  download
                </button>
              ), // Render link
            },
            {
              Header: "Report Reviewed",
              accessor: "report_reviewed",
              disableSortBy: true,
              Cell: ({ row }) => (
                <div
                  onClick={(event) => event.stopPropagation()} // Prevent row click for this column
                  style={{ cursor: "default" }} // Set cursor to default arrow
                >
                  <ReactSwitch checked={row.original.report_reviewed} onChange={() => handleToggle(row.original)} onColor="#2196F3" offColor="#ccc" />
                </div>
              ),
            },
          ]
        : [],
    [reportsState]
  );

  const tableInstance = useTable(
    {
      columns: patientsColumns,
      data: patientsData,
      initialState, // include initialState here
    },
    useGlobalFilter,
    useSortBy
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, preGlobalFilteredRows, setGlobalFilter, state } = tableInstance;

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px" }}>
        {/* Plus button */}

        <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} setGlobalFilter={setGlobalFilter} globalFilter={state.globalFilter} />

        <div style={{ display: "flex", alignItems: "center" }}>
          {["Show Reviewed Reports", "Hide Reviewed Reports"].map((status, index, array) => (
            <React.Fragment key={status}>
              <button
                key={status}
                style={{
                  background: "none",
                  color: activeFilter === status ? "blue" : "gray",
                  cursor: "pointer",
                  border: "none",
                  textDecoration: activeFilter === status ? "underline" : "",
                  padding: "0 10px",
                }}
                onClick={() => toggleFilter(status)}
              >
                {status}
              </button>
              {index < array.length - 1 && (
                <span key={`${status}-separator`} style={{ margin: "0 5px" }}>
                  /
                </span>
              )}{" "}
              {/* add a unique key for separator */}
            </React.Fragment>
          ))}
        </div>
      </div>
      <table className="table table-bordered" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {!column.disableSortBy && column.isSorted ? (
                    column.isSortedDesc ? (
                      <img src={require("../../images/down_arrow.png")} alt="" />
                    ) : (
                      <img src={require("../../images/up_arrow.png")} alt="" />
                    )
                  ) : (
                    !column.disableSortBy && <img src={require("../../images/default.png")} alt="" />
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {subjectsLoading ? (
            <tr>
              <td colSpan={patientsColumns.length}>Loading...</td>
            </tr>
          ) : rows.length > 0 ? (
            rows.map((row, rowIdx) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps({ key: row.original.subject_id || rowIdx })} // Ensure unique key per row
                  onMouseEnter={() => setHoveredRowIndex(rowIdx)}
                  onMouseLeave={() => setHoveredRowIndex(null)}
                  style={{ cursor: "pointer" }}
                >
                  {row.cells.map((cell, cellIdx) => (
                    <td
                      key={cellIdx} // Ensure unique key per cell
                      className={hoveredRowIndex === rowIdx ? "link-primary" : ""}
                      {...cell.getCellProps()}
                      onClick={() => onRowClick(cell)}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={patientsColumns.length}>No Reports Available.</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}
