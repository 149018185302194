import * as actions from "./actionTypes";

export function updateUserSubjects(subjectList) {
    return {
        type: actions.UPDATE_USER_SUBJECTS,
        payload: {
            subjectList: subjectList,
        }
    }
}

export function resetStore() {
    return {
        type: actions.RESET_STORE,
        payload: null,
    }
}

// STILL NEED TO YARN INSTALL IMPORTS FOR REDUX. STILL NEED TO DISPATCH THE ACTIONS.