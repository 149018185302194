import AuthHandler from "./AuthHandler";
import status from "../../config/status";
import { env } from "../../../env";
const REACT_APP_API_URL = env.REACT_APP_API_URL;

export default class APIService {
static url_base = REACT_APP_API_URL;

  get url_base() {
    return this._url_base;
  }
  set url_base(url_base) {
    this._url_base = url_base;
  }

  static generateDynamicPath(base_path, query_params) {
    let param_path = ""
    if (query_params.length > 0) {
        param_path += `?${query_params.join('&')}`
    }
    const final_path = `${base_path}${param_path}`
    return final_path;
  }

  static get(path, query_params = null) {
    if (query_params) {
        path = APIService.generateDynamicPath(path, query_params);
    }

    return new Promise((resolve, reject) => {
      AuthHandler.get_id_token()
        .then((idToken) => {
          fetch(APIService.url_base + path, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: idToken,
            },
          })
            .then((response) => {
              if (response.ok) {
                return response.json();
              } else {
                reject(status.error);
              }
            })
            .then((obj) => {
              let parsedObj = JSON.parse(obj);
              resolve(parsedObj);
            })
            .catch((err) => {
              reject(status.error);
            });
        })
        .catch((returnStatus) => {
          reject(returnStatus);
        });
    });
  }

  static post(path, body) {
    return new Promise((resolve, reject) => {
      AuthHandler.get_id_token()
        .then((idToken) => {
          fetch(APIService.url_base + path, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: idToken,
            },
            body: JSON.stringify(body),
          })
          .then(async (response) => {
            const responseBody = await response.json();  // Try to parse response body whether it's ok or not
            // console.log("RESPONSE BODY");
            // console.log(responseBody);
            if (response.ok) {
              resolve(responseBody);
            } else {
              // console.log("RESPONSE NOT OKAY");
              console.log("Error from API: ", responseBody);  // Log the error message from API
              reject(responseBody);  // You might want to reject with the error message
            }
          })
          .catch((err) => {
            console.log("Error in parsing response: ", err);
            reject(err);
          });
        })
        .catch((returnStatus) => {
          console.log("Error getting ID token: ", returnStatus)
          reject(returnStatus);
        });
    });
  }

  static patch(path, body) {
    return new Promise((resolve, reject) => {
        AuthHandler.get_id_token()
          .then((idToken) => {
            fetch(APIService.url_base + path, {
              method: "PATCH",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: idToken,
              },
              body: JSON.stringify(body),
            })
              .then(async (response) => {
                const responseBody = await response.json();
                // console.log(responseBody)
                if (response.ok) {
                  resolve(responseBody);
                } else {
                  // console.log("RESPONSE NOT OKAY");
                  // console.log("Error from API: ", responseBody);  // Log the error message from API
                  reject(responseBody);  // You might want to reject with the error message
                }
              })
              .catch((err) => {
                // console.log("Error in parsing response: ", err);
                reject(status.error);
              });
          })
          .catch((returnStatus) => {
            // console.log("Error getting ID token: ", returnStatus)
            reject(returnStatus);
          });
      });
  }

  static delete(path, body = null) {
    return new Promise((resolve, reject) => {
        AuthHandler.get_id_token()
          .then((idToken) => {
            fetch(APIService.url_base + path, {
              method: "DELETE",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: idToken,
              },
              body: body,
            })
              .then((response) => {
                if (response.ok) {
                  return response.json();
                } else {
                  reject(status.error);
                }
              })
              .then((obj) => {
                  let parsedObj = JSON.parse(obj);
                  resolve(parsedObj);
              })
              .catch((err) => {
                reject(status.error);
              });
          })
          .catch((returnStatus) => {
            reject(returnStatus);
          });
      });
  }

  static put(path, body) {
    return new Promise((resolve, reject) => {
        AuthHandler.get_id_token()
          .then((idToken) => {
            fetch(APIService.url_base + path, {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: idToken,
              },
              body: body,
            })
              .then((response) => {
                if (response.ok) {
                  return response.json();
                } else {
                  reject(status.error);
                }
              })
              .then((obj) => {
                  let parsedObj = JSON.parse(obj);
                  resolve(parsedObj);
              })
              .catch((err) => {
                reject(status.error);
              });
          })
          .catch((returnStatus) => {
            reject(returnStatus);
          });
      });
  }

}
