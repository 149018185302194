import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usernameValidator } from "../utils/validators/usernameValidator";
import { passwordValidator } from "../utils/validators/passwordValidator";
import Footer from "../components/Footer";
import Global from "../utils/Global";
import auth_errors from "../config/auth_errors";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons

export default function Login() {
  const [username, setUsername] = useState({ value: "", error: "" });
  const [password, setPassword] = useState({ value: "", error: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const onLoginPressed = (event) => {
    event.preventDefault();
    const usernameError = usernameValidator(username.value);
    const passwordError = passwordValidator(password.value);
    if (usernameError || passwordError) {
      setUsername({ ...username, error: usernameError });
      setPassword({ ...password, error: passwordError });
      return;
    }
    if (loading) {
      return;
    }
    setLoading(true);
    Global.localAppHandler
      .app_handle_user_login(username.value, password.value)
      .then((returnStatus) => {})
      .catch((auth_error) => {
        if (auth_error === auth_errors.new_password_needed) {
          navigate("/forceChange", { state: { username: username.value, password: password.value } });
        } else if (auth_error === auth_errors.cog_user_dne) {
          setUsername({ value: username.value, error: "Incorrect username or password" });
          setPassword({
            value: password.value,
            error: "Incorrect username or password",
          });
        } else if (auth_error === auth_errors.incorrect_user_pass) {
          setUsername({ value: username.value, error: "Incorrect username or password" });
          setPassword({
            value: password.value,
            error: "Incorrect username or password",
          });
        } else if (auth_error === auth_errors.too_many_attempts) {
          setPassword({
            value: password.value,
            error: "Password attempts exceeded, please wait a few minutes before trying again",
          });
        } else if (auth_error === auth_errors.get_user_error) {
          setPassword({
            value: password.value,
            error: "Please contact the StrideLink team to finish setting up your account.",
          });
        } else {
          setPassword({
            value: password.value,
            error: "There was an error, please try again.",
          });
        }
        setLoading(false);
      });
  };

  const onForgotPasswordPressed = (event) => {
    event.preventDefault();
    navigate("/recover");
  };

  useEffect(() => {
    document.body.classList.add("bg-primary");

    return () => {
      document.body.classList.remove("bg-primary");
    };
  }, []);
  return (
    <>
      <div>
        <div id="layoutAuthentication">
          <div id="layoutAuthentication_content">
            <main>
              <div className="container-xl px-4">
                <div className="row justify-content-center">
                  <div className="col-lg-5">
                    {/* Basic login form*/}
                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                      <div className="card-header justify-content-center">
                        <div className="text-center">
                          <img className="img-fluid" alt="Logo" src={require("../assets/img/Logo_Final-2.png")} style={{ width: 256, height: 76.8 }} />
                        </div>
                        <h3 className="fw-light my-4">Login</h3>
                      </div>
                      <div className="card-body">
                        {/* Login form*/}
                        <form onSubmit={onLoginPressed}>
                          {/* Form Group (email address)*/}
                          <div className="mb-3">
                            <label className="small mb-1" htmlFor="inputUsername">
                              Username
                            </label>
                            <input
                              value={username.value}
                              onChange={(event) => setUsername({ value: event.target.value.replace(/\s/g, ""), error: "" })}
                              className="form-control"
                              id="inputUsername"
                              type="username"
                              placeholder="Enter username"
                            />
                          </div>
                          {username.error.length > 0 && <div className="tiny alert alert-danger">{username.error}</div>}

                          {/* Form Group (password)*/}
                          <div className="mb-3">
                            <label className="small mb-1" htmlFor="inputPassword">
                              Password
                            </label>
                            <div className="input-group">
                              <input
                                value={password.value}
                                onChange={(event) => setPassword({ value: event.target.value.replace(/\s/g, ""), error: "" })}
                                className="form-control"
                                id="inputPassword"
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter password"
                              />
                              <span
                                className="input-group-text"
                                onClick={() => setShowPassword((prev) => !prev)}
                                aria-label={showPassword ? "Hide password" : "Show password"}
                                style={{ cursor: "pointer" }} // Makes the icon clickable
                              >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                              </span>
                            </div>
                          </div>
                          {password.error.length > 0 && <div className="tiny alert alert-danger">{password.error}</div>}

                          {/* Form Group (remember password checkbox)*/}
                          {/* <div className="mb-3">
                            <div className="form-check">
                              <input className="form-check-input" id="rememberPasswordCheck" type="checkbox" defaultValue />
                              <label className="form-check-label" htmlFor="rememberPasswordCheck">
                                Remember password
                              </label>
                            </div>
                          </div> */}
                          {/* Form Group (login box)*/}
                          <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                            <div className="small link-primary" aria-disabled={loading} onClick={onForgotPasswordPressed}>
                              Forgot Password?
                            </div>
                            <button className={`btn btn-primary ${loading ? "disabled" : ""}`} aria-disabled={loading}>
                              {loading ? "Loading..." : "Login"}
                            </button>
                          </div>
                        </form>
                      </div>
                      <div className="card-footer text-center"></div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
          <div id="layoutAuthentication_footer">
            <Footer light={false} />
          </div>
        </div>
      </div>
    </>
  );
}
