import React, {useState} from "react";
import { useNavigate } from "react-router";
import { RefreshCw } from "react-feather";
import store from "../utils/Redux/store";
import { updateUserSubjects } from "../utils/Redux/actions";
import Global from "../utils/Global";

export default function RefreshButton({subjectsLoading, setSubjectsLoading, ...props}) {
  let navigate = useNavigate();
  const refreshTable = () => {
    setSubjectsLoading(true);
    // console.log("calling get subject list");
    if (!subjectsLoading) {
      Global.localAppHandler
      .app_get_subject_list({include_conditions: true, include_treatments: true, include_code_desc: true})
      .then((subjectList) => {
          // Cache the fetched data in session storage
          store.dispatch(updateUserSubjects(JSON.stringify(subjectList)))
          // sessionStorage.setItem("userSubjects", JSON.stringify(user.subject_summary));
          // setSubjects(user.subject_summary);
          setSubjectsLoading(false);
      })
      .catch((returnStatus) => {
        console.log("get subject list failed")
        store.dispatch(updateUserSubjects(null))
        // setSubjects([]);
        setSubjectsLoading(false);
      });
    }
  }


  return (
    <button
    className={"btn me-2 " + (subjectsLoading ? "btn-primary disabled" : "btn-primary")}
    onClick={refreshTable}
  >
    <RefreshCw size={16} style={{ marginRight: "5px" }} />
    {subjectsLoading ? "Refreshing..." : "Refresh"}
  </button>
  );
}
