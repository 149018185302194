import React from 'react';
import treatment_status_options from '../../config/treatment_status_options';

const RadioButton = ({ id, label, checked, onChange, name }) => {
    return (
      <div className="form-check">
        <input
          className="form-check-input"
          id={id}
          type="radio"
          name={name} // Assuming all radio buttons are part of the same group
          checked={checked}
          onChange={onChange}
        />
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>
      </div>
    );
  };

const TreatmentStatusRadioButtons = ({ treatmentStatus, handleSelect }) => {
  return (
    <div className="mb-3">
      <label className="medium mb-1">
        Is this subject Post-op / Pre-op / Non-op?
      </label>
      <RadioButton
        id="Post-op"
        label="Post-op"
        checked={treatmentStatus === treatment_status_options.post_op}
        onChange={() => handleSelect(treatment_status_options.post_op)}
        name={"treatmentstatus"}
      />
      <RadioButton
        id="Pre-op"
        label="Pre-op"
        checked={treatmentStatus === treatment_status_options.pre_op}
        onChange={() => handleSelect(treatment_status_options.pre_op)}
        name={"treatmentstatus"}
      />
      <RadioButton
        id="Non-op"
        label="Non-op"
        checked={treatmentStatus === treatment_status_options.non_op}
        onChange={() => handleSelect(treatment_status_options.non_op)}
        name={"treatmentstatus"}
      />
    </div>
  );
};

export default TreatmentStatusRadioButtons;