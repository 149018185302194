import User from "../User";
import Subject from "../Subject";
import status from "../../config/status";
import { Amplify, Auth, Hub } from "aws-amplify";
import auth_errors from "../../config/auth_errors";
import store from "../Redux/store";
import { resetStore } from "../Redux/actions";
import { env } from "../../../env";

const REACT_APP_AWS_DEFAULT_REGION = env.REACT_APP_AWS_DEFAULT_REGION;
const REACT_APP_USER_POOL_ID = env.REACT_APP_USER_POOL_ID;
const REACT_APP_CLIENT_ID = env.REACT_APP_CLIENT_ID;

export default class AuthHandler {
  cog_user = null;
  auth_listener = null;

  constructor() {
    this.auth_configure();
    // this.auth_listener = this.setup_auth_listener(); //      return () => Hub.remove("auth", listener);
  }

  checkUser() {
    return new Promise((resolve, reject) => {
      // console.log("check user called")
      Auth.currentUserPoolUser({
        bypassCache: true,
      }).then((authUser) => {
        this.cog_user = authUser;
        resolve(authUser);
      }).catch((error) => {
        this.cog_user = null
        reject(status.error)
      })
    })
  };

  auth_configure() {
    Amplify.configure({
        Auth: {
          // REQUIRED - Amazon Cognito Region
          region: REACT_APP_AWS_DEFAULT_REGION,
      
          // OPTIONAL - Amazon Cognito User Pool ID
          userPoolId: REACT_APP_USER_POOL_ID,
      
          // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
          userPoolWebClientId: REACT_APP_CLIENT_ID,
      
          // // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
          // mandatorySignIn: false,
      
          // // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
          // // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
          // signUpVerificationMethod: 'code', // 'code' | 'link'
      
          // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
          authenticationFlowType: 'USER_PASSWORD_AUTH',
      
          // // OPTIONAL - Hosted UI configuration
          // oauth: {
          //   domain: 'your_cognito_domain',
          //   scope: [
          //     'phone',
          //     'email',
          //     'profile',
          //     'openid',
          //     'aws.cognito.signin.user.admin',
          //   ],
          //   redirectSignIn: 'http://localhost:3000/',
          //   redirectSignOut: 'http://localhost:3000/',
          //   responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
          // },
          storage: sessionStorage,
        },
      });
  }

  setup_auth_listener(updateUICallback) {
    updateUICallback();
    const listener = (data) => {
      if (data.payload.event === "signIn" || data.payload.event === "signOut") {
        updateUICallback();
      }
    };
    Hub.listen("auth", listener);
    this.auth_listener = listener;
  }

  auth_handle_user_login(username, password) {
    return new Promise((resolve, reject) => {
      this.signIn(username, password).then((auth_user) => {
        resolve(auth_user)
      }).catch((returnStatus) => {
        reject(returnStatus);
      })
    })
  }

  auth_handle_logout() {
    return new Promise((resolve, reject) => {
      Auth.signOut().then(() => {
        store.dispatch(resetStore())
        localStorage.clear();
        this.cog_user = null;
        resolve(status.success);
      }).catch(() => {
        reject(status.error);
      })
    })
  }

  auth_handle_forgot_password(username) {
    return new Promise((resolve, reject) => {
      Auth.forgotPassword(username).then((response) => {
        resolve(status.success)
      }).catch((error) => {
        if (error.toString() === "UserNotFoundException: Username/client id combination not found.") {
          reject(auth_errors.incorrect_user_pass)
        } else if (error.toString() === "LimitExceededException: Attempt limit exceeded, please try after some time.") {
          reject(auth_errors.too_many_attempts)
        } else {
          reject(auth_errors.generic_error);
        }
      })
    })
  }

  auth_handle_forgot_password_submit(username, pin, new_password) {
    return new Promise((resolve, reject) => {
      Auth.forgotPasswordSubmit(username, pin, new_password).then((response) => {
        resolve(status.success)
      }).catch((error) => {
        if (error.message === "Invalid verification code provided, please try again.") {
          reject(auth_errors.pin_error);
        } else if (error.message === "Attempt limit exceeded, please try after some time.") {
          reject(auth_errors.too_many_attempts);
        } else {
          reject(auth_errors.generic_error)
        }
      })
    })
  }

  auth_handle_change_password(old_password, new_password) {
    return new Promise((resolve, reject) => {
      Auth.changePassword(this.cog_user, old_password, new_password).then((response) => {
        resolve(status.success);
      }).catch((error) => {
        if (error.toString() === "NotAuthorizedException: Incorrect username or password.") {
          reject(auth_errors.incorrect_user_pass);
        } else if (error.toString() === "LimitExceededException: Attempt limit exceeded, please try after some time.") {
          reject(auth_errors.too_many_attempts);
        } else {
          reject(auth_errors.generic_error);
        }
      })
    })
  }

  auth_handle_force_change_password(new_password) {
    return new Promise((resolve, reject) => {
      Auth.completeNewPassword(
        this.cog_user, // the Cognito User Object
        new_password, // the new password
      ).then((auth_user) => {
        this.cog_user = null; // force a refetch of the cog user..
        resolve(auth_user);
      }).catch((error) => {
        console.log("auth_handle_change_new_password err: " + error);
        reject(auth_errors.new_pass_failed);
      })
  })
  }

  signIn(username, password) {
    return new Promise((resolve, reject) => {
      Auth.signIn(username, password).then((user) => {
        this.cog_user = user
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          reject(auth_errors.new_password_needed);
        } else {
          resolve(user);
        }
      }).catch((error) => {
        console.log('Error confirming sign in with new password', error);
        if (error.message === "User does not exist.") {
          reject(auth_errors.cog_user_dne)
        } else if (error.message === "Incorrect username or password") {
          reject(auth_errors.incorrect_user_pass)
        } else if (error.message === "Incorrect username or password.") {
          reject(auth_errors.incorrect_user_pass)
        } else if (error.message === "Password attempts exceeded") {
          reject(auth_errors.too_many_attempts)
        } else {
          reject(auth_errors.generic_error);
          // console.log(error);
        }
      })
    })
  }

  static get_id_token() {
    return new Promise((resolve, reject) => {
      Auth.currentSession().then((session) => {
        resolve(session.getIdToken().jwtToken)
      }).catch(() => {
        console.log("Failed to get ID Token")
        reject(status.idTokenFail)
      })
    })
  }
}
