import React, { useEffect, useState } from "react";
import Global from "../../utils/Global";
import User from "../../utils/User";
import Form from "../../utils/Form/Form";
import account_types from "../../config/account_types";
const AddTeamModal = ({ isOpen, handleClose, handleSave }) => {
  const [verifying, setVerifying] = useState(false);
  const [errorText, setErrorText] = useState("")
  const [userForm, setUserForm] = useState(null)

  useEffect(() => {
    setErrorText("")
    setUserForm(User.createForm())
  }, [isOpen])

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              Add New User:
            </h5>
            <button
              className="btn-close"
              type="button"
              aria-label="Close"
              onClick={() => {
                handleClose();
              }}
            ></button>
          </div>
          <div className="modal-body">
            <div className="row gx-3">
              <div className="col-md-6">
                <label className="small mb-1" htmlFor="inputUsername">
                  Username:
                </label>
                <input
                  className="form-control mb-2"
                  id="inputUsername"
                  type="text"
                  value={userForm.name.getUI()}
                  onChange={(event) => {
                    userForm.name.handleInputChange(event.target.value.replace(/\s/g, ""));
                    setUserForm(Form.copy(userForm));
                  }
                  }
                />
                {userForm.name.error && <div className="tiny alert alert-danger">{userForm.name.error}</div>}
              </div>

              <div className="col-md-6">
                <label className="small mb-1" htmlFor="inputEmail">
                  Email:
                </label>
                <input
                  className="form-control mb-2"
                  id="inputEmail"
                  type="text"
                  value={userForm.email.getUI()}
                  onChange={(event) => {
                    userForm.email.handleInputChange(event.target.value.replace(/\s/g, ""));
                    setUserForm(Form.copy(userForm));
                  }}
                />
                {userForm.email.error && <div className="tiny alert alert-danger">{userForm.email.error}</div>}
              </div>
            </div>

            <div className="row gx-3">
              <div className="col-md-6">
              <div className="dropdown mb-2">
                <button
                  className="btn btn-primary dropdown-toggle"
                  id="dropdownFadeInUp"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {userForm.account_type.getUI() ? userForm.account_type.getUI() : "Account Type"}
                </button>
                <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                  {Object.values(account_types).map((option, index) => (
                    <div
                      key={index}
                      className="dropdown-item"
                      onClick={(event) => {
                        userForm.account_type.handleInputChange(event.target.innerText);
                        setUserForm(Form.copy(userForm));
                      }}
                    >
                      {option}
                    </div>
                  ))}
                </div>
              </div>
              {userForm.account_type.error && <div className="tiny alert alert-danger">{userForm.account_type.error}</div>}
              </div>
            </div>

            {errorText && <div className="tiny alert alert-danger">{errorText}</div>}
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => {
                if (verifying) {
                  return;
                }
                setVerifying(true);
            
                try {
                  const new_user = User.createFromForm(userForm);
                  Global.localAppHandler
                  .app_post_user(new_user)
                  .then((returnStatus) => {
                    handleSave();
                  })
                  .catch((returnStatus) => {
                    setErrorText("Ensure username and email are unique across all users.");
                  })
                } catch (error) {
                  setUserForm(Form.copy(error.form));
                  console.log(error.form);
                  setVerifying(false);
                  return;
                }
                setVerifying(false);
              }}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTeamModal;
