export default class PrimaryCondition {
    constructor({ code_id = null, code_type, code_value, approximate_date = null, description }) {
      this._code_id = code_id;
      this._code_type = code_type;
      this._code_value = code_value;
      this._approximate_date = approximate_date;
      this._description = description;
    }
  
    static createFromAPI(condition_api) {
      // console.log("CONDITION API")
      // console.log(condition_api);
      return new PrimaryCondition({
        code_id: condition_api.code_id,
        code_type: condition_api.code_type,
        code_value: condition_api.code_value,
        approximate_date: condition_api.approximate_date ? PrimaryCondition.receiveDate(condition_api.approximate_date) : "",  // Convert date format
        description: condition_api.description,
      });
    }

    static checkIfConditionExists(newCondition, existingConditions) {
      let index = existingConditions.findIndex((item) => item.equals(newCondition));
      if (index > -1) {
        newCondition.code_id = existingConditions[index].code_id;
      }
      return newCondition;
    }

    static receiveDate(dateStr) {
      const parts = dateStr.split('-');
      // Assuming the parts array is [YYYY, MM, DD]
      return `${parts[1]}-${parts[2]}-${parts[0]}`;  // Reformat to MM-DD-YYYY
    }

    static sendDate(dateStr) {
      const parts = dateStr.split('-');
      // Assuming the parts array is [MM, DD, YYYY]
      return `${parts[2]}-${parts[0]}-${parts[1]}`;  // Reformat to YYYY-MM-DD
    }
  
    equals(other) {
      // Check if 'other' is an instance of PrimaryCondition
      if (!(other instanceof PrimaryCondition)) {
        return false;
      }
  
      // Compare all relevant properties
      return (
        this._code_type === other._code_type &&
        this._code_value === other._code_value
      );
    }
  
    strictly_equals(other) {
      // Check if 'other' is an instance of PrimaryCondition
      if (!(other instanceof PrimaryCondition)) {
        return false;
      }
  
      // Compare all relevant properties
      return (
        this._code_type === other._code_type &&
        this._code_value === other._code_value &&
        this._approximate_date === other._approximate_date
      );
    }
  
    get code_id() {
      return this._code_id;
    }
    set code_id(code_id) {
      this._code_id = code_id;
    }
  
    get code_type() {
      return this._code_type;
    }
    set code_type(code_type) {
      this._code_type = code_type;
    }
  
    get code_value() {
      return this._code_value;
    }
    set code_value(code_value) {
      this._code_value = code_value;
    }
  
    get approximate_date() {
      return this._approximate_date;
    }
    set approximate_date(approximate_date) {
      this._approximate_date = approximate_date;
    }
  
    get description() {
      return this._description;
    }
    set description(description) {
      this._description = description;
    }
  }


  