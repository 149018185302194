import React from "react";
import account_status from "../../config/account_status";

const EditAccountStatusModal = ({ isOpen, handleClose, handleSave, subject }) => {

  if (!isOpen) return null;

  return (
    <>
        <div className="modal-overlay" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalCenterTitle">
                  Edit Account Status:
                </h5>
                <button className="btn-close" type="button" aria-label="Close" onClick={handleClose}></button>
              </div>
              <div className="modal-body">
                <p>Current Status: <span style={{ fontWeight: 'bold', color: subject.account_status === account_status.active ? 'green' :subject.account_status === account_status.pending ? '#FF8C00' : 'red'}}>{subject.account_status}</span></p>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" type="button" onClick={handleClose}>
                  Cancel
                </button>
                <button className={"btn" + (subject.account_status === account_status.active || subject.account_status === account_status.pending ? " btn-danger" : " btn-primary")} type="button" onClick={handleSave}>
                {subject.account_status === account_status.active || subject.account_status === account_status.pending ? "Deactivate" : "Activate"}
                </button>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default EditAccountStatusModal;
