import Navbar from "../components/NavBars/Navbar";
import React, { useState, useEffect } from "react";
import SideNavbar from "../components/NavBars/SideNavbar";
import Footer from "../components/Footer";
import MainHeader from "../components/Headers/MainHeader";
import Global from "../utils/Global";
import { RemoteAlertsTable } from "../components/Tables/RemoteAlertsTable";
import patient_account_types from "../config/patient_account_types";
import { useNavigate } from "react-router";
import account_status from "../config/account_status";

export default function RemoteAlerts() {
  let navigate = useNavigate();
  const display_type = Global.localAppHandler.user.display_type;

  const [subjectsLoading, setSubjectsLoading] = useState(true);
  const [remoteSubjects, setRemoteSubjects] = useState(null);

  useEffect(() => {
    document.body.classList.add("nav-fixed");

    return () => {
      document.body.classList.remove("nav-fixed");
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 2);
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
        const day = String(currentDate.getDate()).padStart(2, "0");

        setSubjectsLoading(true);
        const pendingSubjects = await Global.localAppHandler.app_get_subject_list({
          include_conditions: true,
          include_code_desc: true,
          account_status: account_status.pending,
          account_type: patient_account_types.remote,
        });
        const activeSubjects = await Global.localAppHandler.app_get_subject_list({
          include_conditions: true,
          include_code_desc: true,
          account_status: account_status.active,
          account_type: patient_account_types.remote,
          most_recent_collection_date: `${year}-${month}-${day}`,
        });

        setRemoteSubjects(pendingSubjects.concat(activeSubjects));
      } catch (error) {
        setRemoteSubjects(null);
      } finally {
        setSubjectsLoading(false);
      }
    };
    fetchData();
  }, [navigate]);

  useEffect(() => {
    document.body.classList.add("nav-fixed");

    return () => {
      document.body.classList.remove("nav-fixed");
    };
  }, []);

  return (
    <>
      <Navbar />
      <div id="layoutSidenav">
        <SideNavbar
          patientSubHeaderEnabled={false}
          activeTab={{ studyOverview: false, subjects: false, manageTeam: false, reviewReports: false, remoteAlerts: true }}
        />
        <div id="layoutSidenav_content">
          <main>
            <MainHeader title="Subjects" />
            {/* Main page content*/}
            <div className="container-xl px-4 mt-n10">
              <div className="card mb-4">
                {/*.card-header Patients*/}
                <div className="card-body">
                  <RemoteAlertsTable
                    subjectsLoading={subjectsLoading}
                    setSubjectsLoading={setSubjectsLoading}
                    subjects={remoteSubjects}
                    displayType={display_type}
                  />
                </div>
              </div>
            </div>
          </main>
          <Footer light={true} />
        </div>
      </div>
    </>
  );
}
