import Navbar from "../components/NavBars/Navbar";
import React, { useState, useEffect } from "react";
import SideNavbar from "../components/NavBars/SideNavbar";
import Footer from "../components/Footer";
import MainHeader from "../components/Headers/MainHeader";
import Global from "../utils/Global";
import { useSelector } from "react-redux";
import store from "../utils/Redux/store";
import { updateUserSubjects } from "../utils/Redux/actions";
import { ReviewReportsTable } from "../components/Tables/ReviewReportsTable";

export default function ReviewReports() {
  const display_type = Global.localAppHandler.user.display_type;

  const [reportsLoading, setReportsLoading] = useState(true);
  const [reports, setReports] = useState(null);

  useEffect(() => {
    const fetchReportsData = async () => {
      try {
        setReportsLoading(true);

        const reports = await Global.localAppHandler.app_get_report_list({
          include_latest_only: true,
        });

        setReports(reports);
      } catch (error) {
        setReports([])
      } finally {
        setReportsLoading(false);
      }
    };
    fetchReportsData();
  }, []);
  
  useEffect(() => {
    document.body.classList.add("nav-fixed");
    let isMounted = true;

    setReportsLoading(true);

    // Try to get the cached data from session storage
    // const cachedSubjects = sessionStorage.getItem("userSubjects");
    // if (reportsLoading) {
      setReports([])
      setReportsLoading(false);
    // }


    return () => {
      isMounted = false;
      document.body.classList.remove("nav-fixed");
    };
  }, []);

  useEffect(() => {
    document.body.classList.add("nav-fixed");
    let isMounted = true;

    return () => {
      isMounted = false;
      document.body.classList.remove("nav-fixed");
    };
  }, []);

  return (
    <>
      <Navbar />
      <div id="layoutSidenav">
      <SideNavbar patientSubHeaderEnabled={false} activeTab={{ studyOverview: false, subjects: false, manageTeam: false, reviewReports: true, remoteAlerts: false }} />
        <div id="layoutSidenav_content">
          <main>
            <MainHeader title="Subjects" />
            {/* Main page content*/}
            <div className="container-xl px-4 mt-n10">
              <div className="card mb-4">
                {/*.card-header Patients*/}
                <div className="card-body">
                  <ReviewReportsTable subjectsLoading={reportsLoading} setSubjectsLoading={setReportsLoading} reports={reports} displayType={display_type}/>
                </div>
              </div>
            </div>
          </main>
          <Footer light={true} />
        </div>
      </div>
    </>
  );
}
