import PatchWrapperArray from "./PatchWrapperArray";
import PatchWrapperSingle from "./PatchWrapperSingle";
import Form from "./Form";
import patch_options from "../../config/patch_options";

export default class PatchForm extends Form {
  constructor({ properties }) {
    super({ properties });
  }

  static copy(oldForm) {
    const properties = {};
    for (const [key, fieldWrapper] of Object.entries(oldForm)) {
      // Check the instance type and create a new instance accordingly
      if (fieldWrapper instanceof PatchWrapperSingle) {
        properties[key] = new PatchWrapperSingle({
          attribute_name: fieldWrapper.attribute_name,
          value: fieldWrapper.value,
          error: fieldWrapper.error,
          value_validator: fieldWrapper.value_validator,
          // Add other necessary properties
        });
      } else if (fieldWrapper instanceof PatchWrapperArray) {
        properties[key] = new PatchWrapperArray({
          attribute_name: fieldWrapper.attribute_name,
          value: fieldWrapper.value, // Ensure this handles the array of values correctly
          error: fieldWrapper.error,
          value_validator: fieldWrapper.value_validator,
          // Add other necessary properties
        });
      }
    }

    const newForm = new PatchForm({ properties: properties });
    return newForm;
  }

  generatePatchSet() {
    let patchset = [];
    for (const [key, fieldWrapper] of Object.entries(this)) {
      if (fieldWrapper instanceof PatchWrapperSingle) {
        if (fieldWrapper.value.op !== patch_options.none) {
          patchset.push({
            op: fieldWrapper.value.op,
            path: "/" + fieldWrapper.attribute_name,
            value: fieldWrapper.value.value,
          });
        }
      } else if (fieldWrapper instanceof PatchWrapperArray) {
        for (const patch of fieldWrapper.value) {
          if (patch.op !== patch_options.none) {
            patchset.push({
              op: patch.op,
              path: "/" + fieldWrapper.attribute_name,
              value: patch.value,
            });
          }
        }
      }
    }
    return patchset;
  }
}
