import AuthHandler from "./AuthHandler";
import status from "../../config/status";
import auth_errors from "../../config/auth_errors";
import User from "../User";
import Subject from "../Subject";
import Treatment from "../Treatment";
import PrimaryCondition from "../PrimaryCondition";
import Trial from "../Trial";
import assistive_devices from "../../config/assistive_devices";
import footwear from "../../config/footwear";
import treatment_status_options from "../../config/treatment_status_options";

export default class LocalAppHandler {
  authHandler = undefined;
  user = null;
  subjectList = null;
  active_subject = null;

  constructor() {
    this.authHandler = new AuthHandler();
  }
  // AUTH Code
  // =================================================================================

  app_handle_user_login(username, password) {
    return new Promise((resolve, reject) => {
      this.authHandler
        .auth_handle_user_login(username, password)
        .then((auth_user) => {
          this.app_get_user()
            .then((user) => {
              resolve(status.success);
            })
            .catch((returnStatus) => {
              reject(returnStatus);
            });
          // this.app_handle_set_token(auth_user).then((returnStatus) => {
          //   resolve(returnStatus)
          // }).catch((err_message) => {
          //   reject(err_message)
          // })
        })
        .catch((err_message) => {
          reject(err_message);
        });
    });
  }

  app_handle_logout() {
    return new Promise((resolve, reject) => {
      this.authHandler
        .auth_handle_logout()
        .then((returnStatus) => {
          this.user = null;
          this.subjectList = null;
          this.active_subject = null;
          resolve(returnStatus);
        })
        .catch((returnStatus) => {
          reject(returnStatus);
        });
    });
  }

  app_handle_forgot_password(username) {
    return new Promise((resolve, reject) => {
      this.authHandler
        .auth_handle_forgot_password(username)
        .then((returnStatus) => {
          resolve(returnStatus);
        })
        .catch((auth_error) => {
          reject(auth_error);
        });
    });
  }

  app_handle_forgot_password_submit(username, pin, new_password) {
    return new Promise((resolve, reject) => {
      this.authHandler
        .auth_handle_forgot_password_submit(username, pin, new_password)
        .then((returnStatus) => {
          resolve(returnStatus);
        })
        .catch((auth_error) => {
          reject(auth_error);
        });
    });
  }

  app_handle_change_password(old_password, new_password) {
    return new Promise((resolve, reject) => {
      this.authHandler
        .auth_handle_change_password(old_password, new_password)
        .then((returnStatus) => {
          resolve(returnStatus);
        })
        .catch((auth_error) => {
          reject(auth_error);
        });
    });
  }

  app_handle_force_change_password(new_password) {
    return new Promise((resolve, reject) => {
      this.authHandler
        .auth_handle_force_change_password(new_password)
        .then((auth_user) => {
          this.app_get_user()
            .then((user) => {
              resolve(status.success);
            })
            .catch((returnStatus) => {
              reject(returnStatus);
            });
        })
        .catch((returnStatus) => {
          reject(returnStatus);
        });
    });
  }

  app_setup_auth_listener(updateUICallback) {
    this.authHandler.setup_auth_listener(updateUICallback);
  }

  app_check_user_status() {
    return new Promise((resolve, reject) => {
      this.authHandler
        .checkUser()
        .then((cog_user) => {
          this.app_get_user()
            .then((user) => {
              resolve(user);
            })
            .catch((returnStatus) => {
              // this.user = null;
              // this.subjectList = null;
              // this.active_subject = null;
              reject(returnStatus);
            });
        })
        .catch((returnStatus) => {
          reject(returnStatus);
        });
    });
  }

  // API Code
  // =================================================================================

  app_get_user() {
    return new Promise((resolve, reject) => {
      User.getUser()
        .then((user) => {
          this.user = user;
          resolve(this.user);
        })
        .catch((returnStatus) => {
          console.log("app_handler_error: get_user");
          reject(returnStatus);
        });
    });
  }

  app_get_subject_name() {
    return new Promise((resolve, reject) => {
      Subject.getSubjectName()
        .then((subject_name) => {
          resolve(subject_name);
        })
        .catch((returnStatus) => {
          reject(returnStatus);
        });
    });
  }

  app_post_subject(subject) {
    return new Promise((resolve, reject) => {
      Subject.postSubject(subject)
        .then((subject) => {
          resolve(subject);
        })
        .catch((returnStatus) => {
          reject(returnStatus);
        });
    });
  }

  app_patch_subject(subjectForm) {
    return new Promise((resolve, reject) => {
      Subject.patchSubject(this.active_subject.subject_id, subjectForm)
        .then((subject) => {
          resolve(subject);
        })
        .catch((returnStatus) => {
          reject(returnStatus);
        });
    });
  }

  app_resend_confirmation_code() {
    return new Promise((resolve, reject) => {
      Subject.resendConfirmationCode(this.active_subject.subject_id)
        .then((returnStatus) => {
          resolve(returnStatus)
        })
        .catch((returnStatus) => {
          reject(returnStatus);
        })
    })
  }

  app_get_subject() {
    return new Promise((resolve, reject) => {
      Subject.getSubject(this.active_subject.subject_id, true, true, true, true, true)
        .then((subject) => {
          this.active_subject = subject;
          resolve(this.active_subject);
        })
        .catch((returnStatus) => {
          console.log("app_handler_error: get_subject");
          reject(returnStatus);
        });
    });
  }

  app_set_subject(subject) {
    return new Promise((resolve, reject) => {
      this.active_subject = subject;
      resolve(subject.subject_id);
    });
  }

  app_retrieve_period_information({ include_current_period = true, include_all_periods = false, include_trials = false, include_report = false } = {}) {
    return new Promise((resolve, reject) => {
      Subject.getPeriod({
        subject_id: this.active_subject.subject_id,
        include_current_period: include_current_period,
        include_all_periods: include_all_periods,
        include_trials: include_trials,
        include_report: include_report
      })
        .then((period) => {
          resolve(period);
        })
        .catch((returnStatus) => {
          reject(returnStatus);
        });
    });
  }

  app_get_subject_list({
    include_conditions = false,
    include_treatments = false,
    include_code_desc = false,
    include_days_pending = false,
    account_status = null,
    account_type = null,
    most_recent_collection_date = null,
  } = {}) {
    return new Promise((resolve, reject) => {
      Subject.getSubjectList({
        include_conditions: include_conditions,
        include_treatments: include_treatments,
        include_code_desc: include_code_desc,
        include_days_pending: include_days_pending,
        account_status: account_status,
        account_type: account_type,
        most_recent_collection_date: most_recent_collection_date,
      })
        .then((subjectList) => {
          this.subjectList = subjectList;
          resolve(subjectList);
        })
        .catch((returnStatus) => {
          reject(returnStatus);
        });
    });
  }

  app_get_trial_list({ include_metrics = false, start_date = null, end_date = null, date = null, trial_name = null } = {}) {
    return new Promise((resolve, reject) => {
      Subject.getTrialList(this.active_subject.subject_id, include_metrics, start_date, end_date, date, trial_name)
        .then((trialList) => {
          for (let i = 0; i < trialList.length; i++) {
            trialList[i] = this.prepareTrial(trialList[i]);
          }
          resolve(trialList);
        })
        .catch((returnStatus) => {
          reject(returnStatus);
        });
    });
  }

  app_get_report_list({ include_latest_only = false} = {}) {
    return new Promise((resolve, reject) => {
      Subject.getReportList(include_latest_only)
        .then((reportList) => {
          resolve(reportList);
        })
        .catch((returnStatus) => {
          reject(returnStatus);
        })
    })
  }
  app_get_report(subject_id, report_id) {
    return new Promise((resolve, reject) => {
      Subject.getReport(subject_id, report_id)
        .then((returnStatus) => {
          resolve(returnStatus);
        })
        .catch((returnStatus) => {
          reject(returnStatus);
        })
    })
  }

  app_set_report_reviewed(subject_id, report_id, report_reviewed) {
    return new Promise((resolve, reject) => {
      Subject.setReportReviewed(subject_id, report_id, report_reviewed)
        .then((returnStatus) => {
          resolve(returnStatus);
        })
        .catch((returnStatus) => {
          reject(returnStatus);
        })
    })
  }

  app_set_active_trial(trial_id) {
    return new Promise((resolve, reject) => {
      this.active_subject.active_trial = new Trial({
        subject_id: null,
        trial_id: trial_id,
        trial_name: null,
        trial_date: null,
        footwear_left: null,
        footwear_right: null,
        assistive_device: null,
        vas_pain_score: null,
        trial_data: null,
      });
      resolve(trial_id);
    });
  }

  app_get_trial({ include_metrics = false } = {}) {
    return new Promise((resolve, reject) => {
      Subject.getTrial(this.active_subject.subject_id, this.active_subject.active_trial.trial_id, include_metrics)
        .then((trial) => {
          trial = this.prepareTrial(trial);
          this.active_subject.active_trial = trial;
          resolve(this.active_subject.active_trial);
        })
        .catch((returnStatus) => {
          reject(returnStatus);
        });
    });
  }

  app_get_active_subject() {
    return this.active_subject;
  }

  app_get_user_list() {
    return new Promise((resolve, reject) => {
      User.getUserList(true, null, null)
        .then((team) => {
          resolve(team);
        })
        .catch((returnStatus) => {
          reject(returnStatus);
        });
    });
  }

  app_post_user(user) {
    return new Promise((resolve, reject) => {
      User.postUser(user)
        .then((user) => {
          resolve(user);
        })
        .catch((returnStatus) => {
          reject(returnStatus);
        });
    });
  }

  app_patch_user(userForm) {
    return new Promise((resolve, reject) => {
      User.patchUser(userForm)
        .then((user) => {
          resolve(user);
        })
        .catch((returnStatus) => {
          reject(returnStatus);
        });
    });
  }

  prepareSubject(subjectObj) {
    const primary_condition = new PrimaryCondition(
      subjectObj.primary_condition.code_type,
      subjectObj.primary_condition.code_value,
      null,
      subjectObj.primary_condition.description
    );

    let treatments = [];
    subjectObj.treatment_status = parseInt(subjectObj.treatment_status);

    if (subjectObj.treatment_status === treatment_status_options.post_op) {
      for (let i = 0; i < subjectObj.treatments.length; i++) {
        treatments.push(
          new Treatment(
            subjectObj.treatments[i].code_type,
            subjectObj.treatments[i].code_value,
            subjectObj.treatments[i].weeks,
            subjectObj.treatments[i].sls_modifier,
            subjectObj.treatments[i].description
          )
        );
      }
    } else {
      treatments = null;
    }

    return new Subject(
      subjectObj.subject_id,
      subjectObj.name,
      subjectObj.age,
      subjectObj.sex,
      subjectObj.height,
      subjectObj.weight,
      primary_condition,
      treatments,
      subjectObj.treatment_status,
      subjectObj.last_active, // TODO: will likely need to change this to be in local time
      subjectObj.trials // change trials
    );
  }

  prepareTrial(trial) {
    if (trial.trial_metrics) {
      let stance_asi = Math.abs(parseFloat(trial.trial_metrics.stance_asi));
      trial.trial_metrics.stance_asi = isNaN(stance_asi) ? NaN : stance_asi;
      let swing_asi = Math.abs(parseFloat(trial.trial_metrics.swing_asi));
      trial.trial_metrics.swing_asi = isNaN(swing_asi) ? NaN : swing_asi;
      let vas_pain_score = parseFloat(trial.vas_pain_score);
      trial.vas_pain_score = isNaN(vas_pain_score) ? NaN : vas_pain_score;
      let left_average_stance_time = parseFloat(trial.trial_metrics.left_metrics.average_stance_time);
      trial.trial_metrics.left_metrics.average_stance_time = isNaN(left_average_stance_time) ? NaN : left_average_stance_time;
      let right_average_stance_time = parseFloat(trial.trial_metrics.right_metrics.average_stance_time);
      trial.trial_metrics.right_metrics.average_stance_time = isNaN(right_average_stance_time) ? NaN : right_average_stance_time;
    }
    return trial;
  }
}
