import React from 'react';
import patient_account_types from '../../config/patient_account_types';
import display_types from '../../config/display_types';

const RadioButton = ({ id, label, checked, onChange, name, disabled }) => {
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        id={id}
        type="radio"
        name={name} // Assuming all radio buttons are part of the same group
        checked={checked}
        onChange={onChange}
        disabled={disabled} // Add this line
      />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

const AccountTypeRadioButtons = ({ accountType, displayType, handleSelect }) => {
  return (
    <div className="mb-3">
      <label className="medium mb-1">
        Will this user be part of a remote monitoring program?
      </label>
      <RadioButton
        id="remoteaccess"
        label="Remote Access"
        checked={accountType === patient_account_types.remote}
        onChange={() => handleSelect(patient_account_types.remote)}
        name={"accounttype"}
      />
      <RadioButton
        id="inclinic"
        label="In-Clinic Only"
        disabled={displayType === display_types.PHI}
        checked={accountType === patient_account_types.inclinic}
        onChange={() => handleSelect(patient_account_types.inclinic)}
        name={"accounttype"}
      />
    </div>
  );
};

export default AccountTypeRadioButtons;