export default {
    cog_user_dne: 0x00,
    incorrect_user_pass: 0x01,
    too_many_attempts: 0x02,
    new_password_needed: 0x03,
    get_user_error: 0x04,
    new_pass_failed: 0x05,
    wifi_disabled: 0x06,
    pin_error: 0x07,
    generic_error: 0x08
  };