import React, { useState } from "react";
import treated_side from "../../config/treated_side";

const TreatmentModal = ({ weeksPostOp, setWeeksPostOp, affectedSide, setAffectedSide, isOpen, handleClose, handleSave, selectedOption }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              Selected Option:{" "}
            </h5>
            <button className="btn-close" type="button" aria-label="Close" onClick={() => {
                              setWeeksPostOp({
                                value: "",
                                error: "",
                            });
                            setAffectedSide({
                                value: "",
                                error: "",
                            });
              handleClose()
              }}></button>
          </div>
          <div className="modal-body">
            <p>{selectedOption}</p>
            <div className="row gx-3 mb-3">
              <div className="col-md-6">
                <label className="small mb-1" htmlFor="inputWeight">
                  Weeks post-op:
                </label>
                <input
                  className="form-control mb-2"
                  id="inputWeeksPostop"
                  type="number"
                  min={0}
                  value={weeksPostOp.value}
                  onKeyPress={(e) => {
                    // Allow only digits and prevent hyphens and other special characters
                    const allowedCharacters = /^[0-9]+$/;
                    if (!allowedCharacters.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(event) => {
                    setWeeksPostOp({ value: event.target.value, error: "" });
                  }}
                />
                {weeksPostOp.error && <div className="tiny alert alert-danger">{weeksPostOp.error}</div>}
              </div>

              <div className="col-md-6 align-items-end mt-4">
                <div className="dropdown mb-2">
                  <button
                    className="btn btn-primary dropdown-toggle"
                    id="dropdownFadeInUp"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {affectedSide.value ? affectedSide.value : "Treated Side"}
                  </button>
                  <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                    {treated_side.side_options.map((option, index) => (
                      <div
                        key={index}
                        className="dropdown-item"
                        onClick={(event) => {
                          setAffectedSide({ value: event.target.textContent, error: "" });
                        }}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                </div>
                {affectedSide.error && <div className="tiny alert alert-danger">{affectedSide.error}</div>}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" type="button" onClick={() => {
                setWeeksPostOp({
                    value: "",
                    error: "",
                });
                setAffectedSide({
                    value: "",
                    error: "",
                });
                handleClose()
            }}>
              Close
            </button>
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => {
                const weeksValue = parseInt(weeksPostOp.value, 10); // Parse to integer with base 10
                const weeksError = weeksPostOp.value === "" || isNaN(weeksValue) ? "Enter # weeks between 0-520" : 
                weeksValue > 520 ? "Enter # weeks between 0-520" :
                "";

                const affectedSideError = affectedSide.value === "" ? "Select treated side" : "";

                if (weeksError || affectedSideError) {
                  setWeeksPostOp({
                    ...weeksPostOp,
                    error: weeksError,
                  });
                  setAffectedSide({
                    ...affectedSide,
                    error: affectedSideError,
                  });
                  return;
                }
                handleSave(weeksPostOp.value, affectedSide.value);
                setWeeksPostOp({
                  value: "",
                  error: "",
              });
              setAffectedSide({
                  value: "",
                  error: "",
              });
              }}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreatmentModal;
{
  /* <div className="modal fade" id="exampleModalCenter" tabindex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
<div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
        <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">Selected Option: </h5>
            <p>{selectedOption}</p>
            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
        </div>
        <div className="modal-body">...</div>
        <div className="modal-footer"><button class="btn btn-secondary" type="button" data-bs-dismiss="modal">Close</button><button class="btn btn-primary" type="button">Save changes</button></div>
    </div>
</div>
</div> */
}
