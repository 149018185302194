// ErrorPopup.js
import React from 'react';
import '../../css/ErrorPopup.css'

const ErrorPopup = ({ message, onClose }) => {
    return (
        <div className="error-popup-overlay">
            <div className="error-popup">
                <h2>Error</h2>
                <p>{message}</p>
                <button className ="btn btn-primary" onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default ErrorPopup;