export function onCheckWeeks(text) {
  const parsedWeeks = parseInt(text);
  let toReturn;
  if (Number.isNaN(parsedWeeks)) {
    toReturn = "";
  } else if (parsedWeeks > 520) {
    toReturn = "520";
  } else {
    toReturn = parsedWeeks.toString();
  }
  return toReturn;
}

export function onCheckAge(parsedAge) {
  let toReturn;
  if (Number.isNaN(parsedAge)) {
    toReturn = "";
  } else if (parsedAge > 100) {
    toReturn = 100;
  } else {
    toReturn = parsedAge;
  }
  return toReturn;
}

export function onCheckHeight(parsedHeight) {
  let toReturn
  if (Number.isNaN(parsedHeight)) {
    toReturn = "";
  } else if (parsedHeight > 96) {
    toReturn = 96;
  } else {
    toReturn = parsedHeight;
  }
  return toReturn;
}

export function onCheckWeight(parsedWeight) {
  let toReturn;
  if (Number.isNaN(parsedWeight)) {
    toReturn = "";
  } else if (parsedWeight > 800) {
    toReturn = 800;
  } else {
    toReturn = parsedWeight;
  }
  return toReturn;
}

export function onCheckTrialName(text) {
  var toReturn = text.replace(/[^a-zA-Z0-9]/g, "");
  return toReturn;
};