import React, { useState, useEffect, useRef } from "react";
import { Doughnut } from "react-chartjs-2";
import Chart, { ChartData, ChartOptions } from "chart.js/auto";
export default function SexDemographicsPieChart({ data, ...props }) {
  
  const sexDataRef = useRef(null);

  const [sexDataChart, setSexDataChart] = useState<ChartData<'doughnut'>>({
    labels: ["M", "F", "X"],
    datasets: [
      {
        label: "Sex",
        data: [data.M, data.F, data.X],
        backgroundColor: ["blue", "#39a4db", "gray"],
        borderColor: ["white", "white", "white"],
      },
    ],
  });

  const [sexDataOptions, setSexDataOptions] = useState<ChartOptions<'doughnut'>>({
    plugins: {
      legend: {
        display: false, // You might want to display the legend for clarity
      },
      tooltip: {
        enabled: true,
      },
      datalabels: {
        display: true,
        color: 'white', // or whatever color you prefer
        formatter: function(value, context) {
          // Check if value is greater than 0 to avoid displaying empty sections
          if (value > 0) {
            // Combine the section name (label) with its value
            return context.chart.data.labels[context.dataIndex] + ': ' + value;
          } else {
            return ''; // This will prevent showing 0 values
          }
        }
      },
    },
    // maintainAspectRatio: true,
    // responsive: true,
  });

  return (
    <div className="card mb-4">
      <div className="sexDemographicsPieChart">
        <div className="d-flex card-header align-items-center justify-content-between">Sex Demographics</div>
        <div className="card-body">
          <Doughnut
            ref={sexDataRef}
            data={sexDataChart}
            height={30}
            width={30}
            options={sexDataOptions}
            style={{ alignSelf: "center", margin: 0 }}
          ></Doughnut>
        </div>
        <div className="card-footer small text-muted" />
      </div>
    </div>
  );
}
