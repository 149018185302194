import Form from "./Form/Form";
import FieldWrapperSingle from "./Form/FieldWrapperSingle";
import { onCheckTrialName } from "./validators/checkInputs";
import FormError from "./Errors/FormError";
import status from "../config/status";
import APIService from "./Services/APIService";
import moment from "moment";

function average(array) {
  const filteredArray = array.filter(value => !isNaN(value) && value !== null);
  if (filteredArray.length === 0) return NaN; // Return NaN if there are no valid numbers to average
  return parseFloat((filteredArray.reduce((a, b) => a + b, 0) / filteredArray.length).toFixed(2));
}

export default class Trial {
  constructor({
    subject_id,
    trial_id,
    trial_name,
    trial_date,
    footwear_left,
    footwear_right,
    assistive_device,
    vas_pain_score,
    trial_data,
    trial_metrics
  }
  ) {
    this._subject_id = subject_id;
    this._trial_id = trial_id;
    this._trial_name = trial_name;
    this._trial_date = trial_date;
    this._footwear_left = footwear_left;
    this._footwear_right = footwear_right;
    this._assistive_device = assistive_device;
    this._vas_pain_score = vas_pain_score;
    this._trial_data = trial_data;
    this._trial_metrics = trial_metrics;
  }

  static createFromAPI(trial_api) {
    let localDate = trial_api.trial_date ? (moment.utc(trial_api.trial_date).local()).format('YYYY-MM-DD HH:mm:ss') : trial_api.trial_date;
    return new Trial({subject_id: trial_api.subject_id,
                      trial_id: trial_api.trial_id,
                      trial_name: trial_api.trial_name,
                      trial_date: localDate,
                      footwear_left: trial_api.footwear_left,
                      footwear_right: trial_api.footwear_right,
                      assistive_device: trial_api.assistive_device,
                      vas_pain_score: trial_api.vas_pain_score ? trial_api.vas_pain_score/10 : trial_api.vas_pain_score,
                      trial_metrics: trial_api.trial_metrics})
  }

  static createFromForm(trial_form) {
    const trial = new Trial({}); // Ensure that Subject is defined/imported properly
    const fields = Object.values(trial_form);
    let exceptionOccured = false;

    fields.forEach((field) => {
      try {
        // Assuming 'attribute_name' and 'getUI' are defined in your field's structure
        trial[field.attribute_name] = field.getUI();
      } catch (error) {
        field.error = error.message;
        exceptionOccured = true;
      }
    });

    if (exceptionOccured) {
      // Ensure that FormError is defined/imported properly
      throw new FormError("Form Error Occurred!", trial_form);
    } else {
      return trial;
    }
  }

  get subject_id() { return this._subject_id; }
  set subject_id(subject_id) { this._subject_id = subject_id; }

  get trial_id() { return this._trial_id; }
  set trial_id(trial_id) { this._trial_id = trial_id; }

  get trial_name() { return this._trial_name; }
  set trial_name(trial_name) { 
    if (trial_name) {
      this._trial_name = trial_name; 
    } else {
      throw new Error("Enter Trial name.")
    }
  }

  get trial_date() { return this._trial_date; }
  set trial_date(trial_date) { this._trial_date = trial_date; }

  get footwear_left() { return this._footwear_left; }
  set footwear_left(footwear_left) { this._footwear_left = footwear_left; }

  get footwear_right() { return this._footwear_right; }
  set footwear_right(footwear_right) { this._footwear_right = footwear_right; }

  get assistive_device() { return this._assistive_device; }
  set assistive_device(assistive_device) { this._assistive_device = assistive_device; }

  get vas_pain_score() { return this._vas_pain_score; }
  set vas_pain_score(vas_pain_score) { this._vas_pain_score = vas_pain_score; }

  get trial_data() { return this._trial_data; }
  set trial_data(trial_data) { this._trial_data = trial_data; }

  get trial_metrics() { return this._trial_metrics; }
  set trial_metrics(trial_metrics) { this._trial_metrics = trial_metrics; }

  static createForm(trial = null) {
    return new Form({
      properties: {
        trial_name: new FieldWrapperSingle({ attribute_name: "trial_name", value: trial ? trial.trial_name : "Trial1", error: "", value_validator: onCheckTrialName }),
        assistive_device: new FieldWrapperSingle({ attribute_name: "assistive_device", value: trial ? trial.assistive_device : "None", error: "", value_validator: null }),
        footwear_left: new FieldWrapperSingle({ attribute_name: "footwear_left", value: trial ? trial.footwear_left : "Regular Shoe", error: "", value_validator: null }),
        footwear_right: new FieldWrapperSingle({ attribute_name: "footwear_right", value: trial ? trial.footwear_right : "Regular Shoe", error: "", value_validator: null }),
        vas_pain_score: new FieldWrapperSingle({ attribute_name: "vas_pain_score", value: trial ? trial.vas_pain_score : null, error: "", value_validator: null }),
      },
    });
  }

  static addMetricByDate(metrics_by_date, trial) {
    let date = trial.trial_date.split(" ")[0];
    let formattedDate = moment(date).format('MMMM DD, YYYY');

    if (!metrics_by_date[date]) {
      metrics_by_date[date] = {
        dates: [],
        stance_asis: [],
        swing_asis: [],
        vas_scores: [],
        stance_times_left: [],
        stance_times_right: [],
        trial_name: "",
        // count: 0
      };
    }
    metrics_by_date[date].dates.push(date);
    metrics_by_date[date].vas_scores.push(trial.vas_pain_score);
    metrics_by_date[date].trial_name = trial.trial_name
    if (trial.trial_metrics) {
      metrics_by_date[date].stance_asis.push(trial.trial_metrics.stance_asi);
      metrics_by_date[date].swing_asis.push(trial.trial_metrics.swing_asi);
      metrics_by_date[date].stance_times_left.push(trial.trial_metrics.left_metrics.average_stance_time);
      metrics_by_date[date].stance_times_right.push(trial.trial_metrics.right_metrics.average_stance_time);
      // metrics_by_date[date].count++;
    }
  }

  static extractMetricsFromList(trial_list) {
      let metricsByDate = {};
      for (const trial of trial_list) {
          Trial.addMetricByDate(metricsByDate, trial)
      }

      const trial_metrics = {
        dates: [],
        stance_asis: [],
        swing_asis: [],
        vas_scores: [],
        stance_times_left: [],
        stance_times_right: [],
        trial_names: []
      };

      for (const [date, metrics] of Object.entries(metricsByDate)) {
        // if (metrics.count > 0) {
          trial_metrics.dates.push(date);
          trial_metrics.stance_asis.push(average(metrics.stance_asis));
          trial_metrics.swing_asis.push(average(metrics.swing_asis));
          trial_metrics.vas_scores.push(average(metrics.vas_scores));
          trial_metrics.stance_times_left.push(average(metrics.stance_times_left));
          trial_metrics.stance_times_right.push(average(metrics.stance_times_right));
          trial_metrics.trial_names.push(metrics.trial_name);  // assuming you want the formatted date as the trial name
        // }
      }
      return trial_metrics;
  }

}
