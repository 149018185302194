export default class Report {
  constructor({
    report_id,
    report_reviewed
  }) {
    this._report_id = report_id;
    this._report_reviewed = report_reviewed;
  }

  static createFromAPI(report_api) {
    return new Report({
      report_id: report_api ? report_api.report_id : null,
      report_reviewed: report_api ? report_api.report_reviewed : null,
    });
  }

  get report_id() {
    return this._report_id;
  }
  set report_id(report_id) {
    this._report_id = report_id;
  }

  get report_reviewed() {
    return this._report_reviewed;
  }
  set report_reviewed(report_reviewed) {
    this._report_reviewed = report_reviewed;
  }

}
