import React, { useState, useRef, useEffect, useMemo } from "react";
import { Line } from "react-chartjs-2";
import { tooltipOptions, generalOptions, axesOptions, zoomOptions, layoutOptions } from "../../assets/chart-defaults";
import Chart, { ChartData, ChartOptions } from "chart.js/auto";

export default function SwingASIGraph({ labels, data, trialNames, lastUpdatedTime, timeAxis, timeFrame, ...props }) {
  // Filter out NaN values from data and corresponding labels
  const filteredData = useMemo(() => data.filter(value => !isNaN(value)), [data]);
  const filteredLabels = useMemo(() => labels.filter((_, index) => !isNaN(data[index])), [labels, data]);
  const filteredTrialNames = useMemo(() => trialNames.filter((_, index) => !isNaN(data[index])), [trialNames, data])

  const [minDateStr, maxDateStr] = useMemo(() => {
    const dates = labels.map(label => new Date(label)).filter(date => !isNaN(date.getTime()));
    let min, max;
    if (timeFrame === "alltime") {
      if (dates.length > 0) {
        min = new Date(Math.min(...dates.map(date => date.getTime())));
        max = new Date(Math.max(...dates.map(date => date.getTime())));
      } else {
        // Set to default values if no valid dates are found
        min = new Date();
        max = new Date();
      }
    } else if (timeFrame === "7day") {
      if (dates.length > 0) {
        max = new Date();
        min = new Date(max);
        min.setDate(min.getDate() - 7);
      } else {
        // Set to default values if no valid dates are found
        min = new Date();
        max = new Date();
      }
    } else if (timeFrame === "30day") {
      if (dates.length > 0) {
        max = new Date();
        min = new Date(max);
        min.setDate(min.getDate() - 30);
      } else {
        // Set to default values if no valid dates are found
        min = new Date();
        max = new Date();
      }
    } else if (timeFrame === "90day") {
      if (dates.length > 0) {
        max = new Date();
        min = new Date(max);
        min.setDate(min.getDate() - 90);
      } else {
        // Set to default values if no valid dates are found
        min = new Date();
        max = new Date();
      }
    }
    const range = max.getTime() - min.getTime();
    let padding = 0.2 * range / (1000 * 60 * 60 * 24); // converting from ms to days

    if (padding < 2) {
      padding = 2;
    }

    min.setDate(min.getDate() - padding);
    max.setDate(max.getDate() + padding);

    return [min.toISOString(), max.toISOString()];
  }, [labels, timeFrame]);

const formattedDelta = useMemo(() => {
  const delta = filteredData.length >= 2 ? filteredData[filteredData.length - 1] - filteredData[filteredData.length - 2] : null;
  return (delta !== null && !Number.isNaN(delta)) ? `${delta > 0 ? '+' : ''}${delta.toFixed(2)}%` : 'N/A';
}, [filteredData]);
  
  const swingASIRef = useRef<Chart<'line'>>(null);
  const [lastUpdatedTimeNew, setLastUpdatedTimeNew] = useState<string>(lastUpdatedTime);
  const placeholder_swingASIData = useMemo<ChartData<'line'>>(
    () => ({
      labels: filteredLabels.length > 0 ? filteredLabels : [],
      datasets: [
        {
          type: "line",
          label: "Swing ASI",
          lineTension: 0.0,
          backgroundColor: "rgba(0,97,242,0.1)",
          borderColor: "rgba(0, 97, 242, 1)",
          pointRadius: 3,
          // fill: true,
          pointBackgroundColor: "rgba(0, 97, 242, 1)",
          pointBorderColor: "rgba(0, 97, 242, 1)",
          pointHoverRadius: 3,
          pointHoverBackgroundColor: "rgba(0, 97, 242, 1)",
          pointHoverBorderColor: "rgba(0, 97, 242, 1)",
          pointHitRadius: 10,
          pointBorderWidth: 2,
          data: filteredData.length > 0 ? filteredData : [],
        },
      ],
    }),
    [filteredLabels, filteredData]
  );
  const placeholder_swingASIOptions = useMemo<ChartOptions<'line'>>(
    () => ({
      normalized: generalOptions.normalized,
      animation: generalOptions.animation,
      maintainAspectRatio: generalOptions.maintainAspectRatio,
      layout: layoutOptions.normalLayout,
      scales: {
        x: {
          type: "time",
          time: {
            unit: timeAxis,
          },
          min: minDateStr, // Set min date
          max: maxDateStr, // Set max date
          grid: axesOptions.disabledGrid,
          ticks: {
            callback: function (value, index, values) {
              let toReturn = value;
              // if (timeAxis === "millisecond") {
              //   let splitVal = value.split(" ");
              //   let index = splitVal[0].lastIndexOf(".");
              //   toReturn = splitVal[0].substring(0, index) + " " + splitVal[1];
              // }
              return toReturn;
            },
            maxTicksLimit: axesOptions.maxTicksLimitX,
          },
        },
        y: {
          suggestedMin: 0,
          // max: 100,
          ticks: {
            maxTicksLimit: axesOptions.maxTicksLimitY,
            padding: 20,
            callback: function (value, index, values) {
              return value + "%";
            },
          },
          grid: axesOptions.enabledGrid,
        },
      },
      plugins: {
        legend: false,
        tooltip: {
          titleMarginBottom: tooltipOptions.titleMarginBottom,
          titleColor: tooltipOptions.titleColor,
          titleFont: tooltipOptions.titleFont,
          bodyFont: tooltipOptions.bodyFont,
          backgroundColor: tooltipOptions.backgroundColor,
          bodyColor: tooltipOptions.bodyColor,
          borderColor: tooltipOptions.borderColor,
          borderWidth: tooltipOptions.borderWidth,
          displayColors: tooltipOptions.displayColors,
          caretPadding: tooltipOptions.caretPadding,
          padding: tooltipOptions.padding,
          callbacks: {
            title: function (tooltipItems) {
              let trialName = filteredTrialNames.length > 0 ? filteredTrialNames[tooltipItems[0].dataIndex] : null;
              if (!trialName) {
                    trialName = "Unknown"; // Fallback if name is not available
                }
                return trialName;
            },
            label: function (context) {
                let splitIndex = context.label.lastIndexOf(",");
                let labelValue = context.label;
        
                if (timeAxis === "hour") {
                    labelValue = context.label;
                } else if (timeAxis === "day") {
                    labelValue = context.label.slice(0, splitIndex);
                } else if (timeAxis === "month") {
                    labelValue = context.label.slice(0, 4);
                } else if (timeAxis === "millisecond") {
                    labelValue = context.label;
                }
                return `${labelValue}: ${context.parsed.y}%`;
            },
        },
        },
        datalabels: {
          align: 'end', // You can use 'start', 'end', 'right', 'left', 'top', or 'bottom'
          anchor: 'end', // Anchors the label to the 'end' of the data point
          offset: 4, // Number of pixels to offset the label from the anchor point, adjust as needed
          formatter: (value, context) => {
            const minDate = new Date(minDateStr)
            const maxDate = new Date(maxDateStr)
            const differenceInTime = maxDate.getTime() - minDate.getTime();
            const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);
            // if (Math.round(differenceInDays) <= 17) { // 17 because 7 days w/ 5 days padding on either side
            //   return value + '%'; // Format the datalabel text
            // } else {
              return ""
            // }
          },
          // You can add more styling options here as needed
        },
        zoom: {
          pan: {
            enabled: zoomOptions.panEnabled,
            mode: "x",
          },
          limits: {
            // x: { min: "2021-11-05T23:39:30", max: "2021-11-08T11:39:30" },
            y: { min: 0, max: 600 },
          },
          zoom: {
            wheel: {
              enabled: zoomOptions.zoomEnabled,
            },
            pinch: {
              enabled: zoomOptions.zoomEnabled,
            },
            mode: "x",
          },
        },
      },
    }),
    [timeAxis, filteredTrialNames, minDateStr, maxDateStr]
  );

  useEffect(() => {
    setSwingASIData(placeholder_swingASIData);
    setSwingASIOptions(placeholder_swingASIOptions);
  }, [placeholder_swingASIData, placeholder_swingASIOptions]);

  useEffect(() => {
    setLastUpdatedTimeNew(lastUpdatedTime);
  }, [lastUpdatedTime]);

  const [swingASIData, setSwingASIData] = useState<ChartData<'line'>>(placeholder_swingASIData);
  const [swingASIOptions, setSwingASIOptions] = useState<ChartOptions<'line'>>(placeholder_swingASIOptions);

  return (
    <div className="card mb-4">
      <div className="swingASIGraph">
      <div className="d-flex card-header align-items-center justify-content-between">
    Swing Asymmetry
    <div className="d-flex align-items-center">
        {formattedDelta !== "N/A" && <span style={{fontSize: '0.9rem'}} className="mr-3">Since Last Visit: {formattedDelta}</span>}
        {zoomOptions.panEnabled || zoomOptions.zoomEnabled ? (
            <button
                className="btn btn-transparent-dark rounded-pill"
                type="button"
                onClick={() => {
                    if (swingASIRef.current) {
                        swingASIRef.current.resetZoom();
                    }
                }}
            >
                Reset
            </button>
        ) : (
            ""
        )}
    </div>
</div>
        <div className="card-body">
        <div className="chart-pie" style={{ position: 'relative' }}>
    <Line ref={swingASIRef} data={swingASIData} height={50} width={100} options={swingASIOptions}></Line>
</div>
        </div>
        <div className="card-footer small text-muted">{lastUpdatedTimeNew}</div>
      </div>
    </div>
  );
}
