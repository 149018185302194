import React, { useState, useEffect, useMemo } from "react";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import { PlusCircle } from "react-feather";
import { GlobalFilter } from "../GlobalFilter";
import { useNavigate } from "react-router-dom";
import Global from "../../utils/Global";

export function RemoteCollectionStatusTable({ isLoading, subject, period }) {
  return (
    <>
      <div className="card mb-4">
        <div className="d-flex card-header align-items-center justify-content-between">
          Remote Collection Status
          <div className="d-flex align-items-center"></div>
        </div>
        <div className="card-body">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Current Period</th>
                <th>Days Collected</th>
                <th>Days Remaining in Period</th>
                <th>Day of Last Collection</th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
                <tr>
                  <td>Loading...</td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td>{period.start_date && period.end_date ? period.start_date + " - " + period.end_date : "n/a"}</td>
                  <td>{period.days_collected !== null ? period.days_collected : "n/a"}</td>
                  <td>{period.days_remaining !== null ? period.days_remaining : "n/a"}</td>
                  <td>{subject && subject.most_recent_collection_date ? new Date(subject.most_recent_collection_date).toLocaleString() : "n/a"}</td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
}
