import patch_options from "../../config/patch_options";
import Field from "./Field";

export default class Patch extends Field {
  constructor({ value, original_value, op }) { // Default 'modified' state to 'none'
    super({ value }); // Call the constructor of the parent class (Field)
    this._original_value = original_value;
    this._op = op;
  }

  handlePatch(newVal, desiredAction) {
    switch(desiredAction) {
      // case patch_options.add:
      //   // code block
      //   break;
      // case patch_options.remove:
      //   // code block
      //   break;
      case patch_options.replace:
        switch(this.op) {
          case patch_options.add:
            this.value = newVal;
            break;
          case patch_options.none:
            if (!this.isEqual(newVal, this.original_value)) 
            {
              this.value = newVal;
              this.op = patch_options.replace;
            }
            break;
          case patch_options.remove:
            if (this.isEqual(newVal, this.original_value)) {
              this.op = patch_options.none; 
            } else {
              this.op = patch_options.replace;
            }
            this.value = newVal;
            break;
          case patch_options.replace:
            if (this.isEqual(newVal, this.original_value)) {
              this.op = patch_options.none;
            } 
            this.value = newVal
            break;
        }
        break;
    }
  }

  isEqual(newVal) {
    if (typeof this.original_value === 'object' && typeof newVal === 'object') {
      return this.original_value.strictly_equals(newVal); // if an object use custom equality
    }
    return this.original_value === newVal; // if a primitive use strict equality
  }

  get original_value() {
    return this._original_value;
  }

  set original_value(original_value) {
    this._original_value = original_value;
  }

  get op() {
    return this._op;
  }

  set op(op) {
    this._op = op;
  }
}