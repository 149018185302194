import patch_options from "../../config/patch_options";
import BaseWrapper from "./BaseWrapper";
import Patch from "./Patch";
import structuredClone from "@ungap/structured-clone";
import { cloneDeep } from "lodash";

export default class PatchWrapperArray extends BaseWrapper {
  constructor({ attribute_name, value, error, value_validator }) {
    //, modified = 'none' }) {
    super({ attribute_name: attribute_name, value_validator: value_validator, error: error });
    this._value = value
      ? value.map((val) => (val instanceof Patch ? val : new Patch({ value: val, original_value: cloneDeep(val), op: patch_options.none })))
      : null;
  }

  handleInputChangeListAdd(newVal) {
    if (this.value_validator) {
      newVal = this.value_validator(newVal);
    }
    if (this.value) {
      let index = this.value.findIndex((item) => this.isEqual(item.value, newVal));
      if (index > -1) {
        this.value[index].handlePatch(newVal, patch_options.replace);
      } else {
        this.value.push(new Patch({ value: newVal, original_value: newVal, op: patch_options.add }));
      }
    } else {
      this.value = [new Patch({ value: newVal, original_value: newVal, op: patch_options.add })];
    }
    this.error = "";
  }

  handleInputChangeListReplace(val) {
    if (this.value) {
      let index = this.value.findIndex((item) => this.isEqual(item.value, val));
      if (index > -1) {
        this.value[index].handlePatch(val, patch_options.replace);
      }
    }
    this.error = "";
  }

  handleInputChangeListRemove(val) {
    if (this.value) {
      this.value = this.value.filter((o) => {
        // If the value matches and the op is 'add', remove it (don't include it in the new array)
        if (this.isEqual(o.value, val)) {
          if (o.op === patch_options.add) {
            return false;
          } else {
            o.op = patch_options.remove;
          }
        }
        return true; // Include in the new array
      });
    }
    this.error = "";
  }

  isEqual(val1, val2) {
    if (typeof val1 === "object" && typeof val2 === "object") {
      return val1.equals(val2); // if an object use custom equality
    }
    return val1 === val2; // if a primitive use strict equality
  }

  getUI() {
    return this.value
      ? this.value
          .filter((val) => val.op !== patch_options.remove) // Filter out the items with op === remove
          .map((val) => val.value) // Map the remaining items to their values
      : null;
  }
}
