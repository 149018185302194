import React, { useState, useEffect } from "react";
import AccountTypeRadioButtons from "../Inputs/AccountTypeRadioButtons";
import PatchForm from "../../utils/Form/PatchForm";

const EditAccountTypeModal = ({ isOpen, handleClose, handleSave, subjectForm, setSubjectForm, subject }) => {
  const [errorText, setErrorText] = useState("");

  if (!isOpen) return null;

  return (
    <>
        <div className="modal-overlay" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalCenterTitle">
                  Edit Account Type:
                </h5>
                <button className="btn-close" type="button" aria-label="Close" onClick={handleClose}></button>
              </div>
              <div className="modal-body">
                <div className="row gx-3">
                  <AccountTypeRadioButtons accountType={subjectForm.account_type.getUI()} handleSelect={(option) => {
                  
                  subjectForm.account_type.handleInputChange(option);
                  setSubjectForm(PatchForm.copy(subjectForm));
                  
                  }} />
                </div>
                {errorText && <div className="tiny alert alert-danger">{errorText}</div>}
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" type="button" onClick={handleClose}>
                  Cancel
                </button>
                <button className="btn btn-primary" type="button" onClick={handleSave} disabled={subject.account_type === subjectForm.account_type.getUI()}>
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default EditAccountTypeModal;
