import React, { useState, useEffect, useMemo } from "react";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import { PlusCircle } from "react-feather";
import { GlobalFilter } from "../GlobalFilter";
import { useNavigate } from "react-router-dom";
import Global from "../../utils/Global";

export function RemoteCollectionTable({ periods, ...props }) {

  // Normalize and sort periods by start_date
  const normalizedPeriods = useMemo(() => {
    const periodsArray = periods ? (Array.isArray(periods) ? periods : [periods]) : [];
    return periodsArray.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
  }, [periods]);

  return (
    <>
 <div className="card mb-4">
      <div className="d-flex card-header align-items-center justify-content-between">
        Remote Collection Frequency
        <div className="d-flex align-items-center"></div>
      </div>
      <div className="card-body">
        <div className="table-responsive" style={{ maxHeight: '275px', overflowY: 'auto' }}>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Period Start</th>
                <th>Period End</th>
                <th>Collections</th>
              </tr>
            </thead>
            <tbody>
              {normalizedPeriods.length > 0 ? (
                normalizedPeriods.map((period, index) => (
                  <tr key={index}>
                    <td>{period.start_date ? period.start_date : "n/a"}</td>
                    <td>{period.end_date ? period.end_date : "n/a"}</td>
                    <td>{period.days_collected !== null ? `${period.days_collected}/${period.period_length}` : "n/a"}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>n/a</td>
                  <td>n/a</td>
                  <td>n/a</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </>
  );
}
