import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usernameValidator } from "../utils/validators/usernameValidator";
import Footer from "../components/Footer";
import Global from "../utils/Global";
import auth_errors from "../config/auth_errors";
export default function ForgotPassword() {
  const [username, setUsername] = useState({ value: "", error: "" });

  let navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add("bg-primary");

    return () => {
      document.body.classList.remove("bg-primary");
    };
  }, []);

  const onReturnPressed = (event) => {
    event.preventDefault();
    navigate("/login");
  };

  const onResetPressed = (event) => {
    event.preventDefault();
    const usernameError = usernameValidator(username.value);
    if (usernameError) {
      setUsername({ ...username, error: usernameError });
      return;
    }

    Global.localAppHandler.app_handle_forgot_password(username.value).then((returnStatus) => {
      navigate('/confirmReset', { state: { username: username.value } });
    }).catch((auth_error) => {
      if (auth_error === auth_errors.incorrect_user_pass) {
        setUsername({ value: username.value, error: "Username not found, please make sure you've entered the username correctly." });
      } else if (auth_error === auth_errors.too_many_attempts) {
        setUsername({ value: username.value, error: "Attempt limit exceeded, please try after some time." });
      } else {
        setUsername({ value: username.value, error: "There was an error, please try again." });
      }
    })
  };
  return (
    <>
      <div>
        <div id="layoutAuthentication">
          <div id="layoutAuthentication_content">
            <main>
              <div className="container-xl px-4">
                <div className="row justify-content-center">
                  <div className="col-lg-5">
                    {/* Basic forgot password form*/}
                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                      <div className="card-header justify-content-center">
                        <h3 className="fw-light my-4">Password Recovery</h3>
                      </div>
                      <div className="card-body">
                        <div className="small mb-3 text-muted">Enter your username and we will send you a link to reset your password.</div>
                        {/* Forgot password form*/}
                        <form onSubmit={onResetPressed}>
                          {/* Form Group (email address)*/}
                          <div className="mb-3">
                            <label className="small mb-1" htmlFor="inputUsername">
                              Username
                            </label>
                            <input
                              value={username.value}
                              onChange={(event) => setUsername({ value: event.target.value.replace(/\s/g, ""), error: "" })}
                              className="form-control"
                              id="inputUsername"
                              type="username"
                              placeholder="Username"
                            />
                          </div>
                          {username.error.length > 0 && <div className="tiny alert alert-danger">{username.error}</div>}

                          {/* Form Group (submit options)*/}
                          <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                            <div className="small link-primary" onClick={onReturnPressed}>
                              Return to login
                            </div>
                            <div className="btn btn-primary" onClick={onResetPressed}>
                              Reset Password
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="card-footer text-center">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
          <div id="layoutAuthentication_footer">
            <Footer light={false} />
          </div>
        </div>
      </div>
    </>
  );
}
