import React, { useState } from "react";

export function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const [value, setValue] = useState(globalFilter);
  const onChange = (value) => {
    setGlobalFilter(value || undefined);
  };

  return (
    <div className="pb-2" style={{ width: 200 }}>
      <input
        type="search"
        className="form-control"
        value={value || ""}
        placeholder="Search..."
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      ></input>
    </div>
  );
}
