import React, { useState, useEffect, useMemo } from "react";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import { PlusCircle } from "react-feather";
import { GlobalFilter } from "../GlobalFilter";
import { useNavigate } from "react-router-dom";
import Global from "../../utils/Global";

export function TrialInformationTable({ trial, ...props }) {
  return (
    <>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Paired Step Count</th>
            <th>VAS Pain Score</th>
            <th>Left Footwear</th>
            <th>Right Footwear</th>
            <th>Assistive Device</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{trial && trial.trial_metrics ? trial.trial_metrics.step_count : "Not Calculated"}</td>
            <td>{trial && trial.vas_pain_score !== null && !isNaN(trial.vas_pain_score) ?  trial.vas_pain_score : "n/a"}</td>
            <td>{trial ? trial.footwear_left : "n/a"}</td>
            <td>{trial ? trial.footwear_right : "n/a"}</td>
            <td>{trial ? trial.assistive_device : "n/a"}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
