module.exports = {
  setNewPasswordAccessible: false,
  firstName: "",
  lastName: "",
  providerCode: "",
  username: "",
  userCredentials: undefined,
  patientList: undefined,
  user: null,
  localAppHandler: null,
};
