export default class Treatment {
  constructor({ code_id = null, code_type, code_value, weeks_post_op = null, sls_modifier = null, description }) {
    this._code_id = code_id;
    this._code_type = code_type;
    this._code_value = code_value;
    this._weeks_post_op = weeks_post_op;
    this._sls_modifier = sls_modifier;
    this._description = description;
  }

  static createFromAPI(treatment_api) {
    return new Treatment({
      code_id: treatment_api.code_id,
      code_type: treatment_api.code_type,
      code_value: treatment_api.code_value,
      weeks_post_op: treatment_api.weeks_post_op.toString(),
      sls_modifier: treatment_api.sls_modifier,
      description: treatment_api.description,
    });
  }

  static checkIfTreatmentExists(newTreatment, existingTreatments) {
    let index = existingTreatments.findIndex((item) => item.equals(newTreatment));
    if (index > -1) {
      newTreatment.code_id = existingTreatments[index].code_id;
    }
    return newTreatment;
  }

  equals(other) {
    // Check if 'other' is an instance of Treatment
    if (!(other instanceof Treatment)) {
      return false;
    }

    // Compare all relevant properties
    return (
      this._code_type === other._code_type &&
      this._code_value === other._code_value
    );
  }

  strictly_equals(other) {
    // Check if 'other' is an instance of Treatment
    if (!(other instanceof Treatment)) {
      return false;
    }

    // Compare all relevant properties
    return (
      this._code_type === other._code_type &&
      this._code_value === other._code_value &&
      this._weeks_post_op === other._weeks_post_op &&
      this._sls_modifier === other._sls_modifier
    );
  }

  get code_id() {
    return this._code_id;
  }
  set code_id(code_id) {
    this._code_id = code_id;
  }

  get code_type() {
    return this._code_type;
  }
  set code_type(code_type) {
    this._code_type = code_type;
  }

  get code_value() {
    return this._code_value;
  }
  set code_value(code_value) {
    this._code_value = code_value;
  }

  get weeks_post_op() {
    return this._weeks_post_op;
  }
  set weeks_post_op(weeks_post_op) {
    this._weeks_post_op = weeks_post_op;
  }

  get sls_modifier() {
    return this._sls_modifier;
  }
  set sls_modifier(sls_modifier) {
    this._sls_modifier = sls_modifier;
  }

  get description() {
    return this._description;
  }
  set description(description) {
    this._description = description;
  }
}
