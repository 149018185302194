// DateInput.js
import React, { useRef } from 'react';
import MaskedInput from 'react-text-mask';
import '../../css/DateInput.css';

const DateInput = ({ errorText, description, disabled, onChange, value, ...props }) => {
  const mask = [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]; // Mask for MM-DD-YYYY
  const inputRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === 'Backspace') {
      const { selectionStart } = event.target;
      if (
        selectionStart > 0 && // Check that selectionStart is greater than 0
        value &&
        value[selectionStart - 1] === '-'
      ) {
        // Move cursor back before the dash
        event.preventDefault(); // Prevent the default backspace action
        const newPosition = selectionStart - 1;
        if (inputRef.current) {
          inputRef.current.setSelectionRange(newPosition, newPosition);
        }
      }
    }
  };

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <>
      <MaskedInput
        mask={mask}
        ref={(ref) => {
          if (ref) {
            inputRef.current = ref.inputElement;
          } else {
            inputRef.current = null;
          }
        }}
        className={`form-control mb-2 date-input ${
          disabled ? 'disabled' : ''
        } ${errorText ? 'error has-error' : ''}`}
        placeholder="MM-DD-YYYY"
        guide={false}
        disabled={disabled}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        {...props}
      />
      {description && !errorText ? <div className="small mb-1">{description}</div> : null}
      {errorText ? <div className="small mb-1 error-text">{errorText}</div> : null}
    </>
  );
};

export default DateInput;
