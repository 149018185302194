import React, { useState, useRef, useEffect, useMemo } from "react";

import { Bar } from "react-chartjs-2";
import { tooltipOptions, generalOptions, axesOptions, zoomOptions, layoutOptions } from "../../assets/chart-defaults";
import Chart, { ChartData, ChartOptions } from "chart.js/auto";
const POST_OP_COLOR = "#39a4db";  // Example color for post-op
const HEIGHT_PER_TREATMENT = 30; 
const CHART_PADDING = 100;



export default function TreatmentDemographics({data, lastUpdatedTime, timeAxis, ...props }) {
  const [lastUpdatedTimeNew, setLastUpdatedTimeNew] = useState<string>(lastUpdatedTime);


  // Calculate the chart height based on the number of conditions
  const chartHeight = Object.keys(data).length * HEIGHT_PER_TREATMENT + CHART_PADDING;

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [{
      label: 'Treatment Count',
      data: [],
      backgroundColor: POST_OP_COLOR,
      borderColor: 'white',
      borderWidth: 1,
    }],
  });

  useEffect(() => {
    // Extracting labels (keys) and data (counts)
    const labels = Object.keys(data);
    const counts = labels.map(key => data[key].count);

    // Update chartData state
    setChartData({
      labels: labels,
      datasets: [{
        label: 'Treatment Count',
        data: counts,
        backgroundColor: POST_OP_COLOR,
        borderColor: 'white',
        borderWidth: 1,
      }],
    });
  }, [data]);

  const chartOptions = useMemo<ChartOptions<'bar'>>(
    () => ({
      normalized: generalOptions.normalized,
      animation: generalOptions.animation,
      maintainAspectRatio: generalOptions.maintainAspectRatio,
      layout: layoutOptions.normalLayout,
      indexAxis: 'y',
      scales: {
        x: {
          stacked: true,
          ticks: {
            maxTicksLimit: axesOptions.maxTicksLimitX,
            padding: 10,
            callback: function (value, index, values) {
              return value;
            },
          },
          grid: axesOptions.enabledGrid,
        },
        y: {
          stacked: true,
          grid: axesOptions.disabledGrid,
          // ticks: {
          //   callback: function (value, index, values) {
          //     let conditionCode = conditions[index];
          //     let description = data[conditionCode]?.description || '';
          //     return `${conditionCode}: ${description}`;
          //   },
          // },
        },
      },
      plugins: {
        legend: {
          display: false,  // Display the legend
          position: 'top'  // Position can be 'top', 'right', 'bottom', or 'left'
        },
        tooltip: {
          titleMarginBottom: tooltipOptions.titleMarginBottom,
          titleColor: tooltipOptions.titleColor,
          titleFont: tooltipOptions.titleFont,
          bodyFont: tooltipOptions.bodyFont,
          backgroundColor: tooltipOptions.backgroundColor,
          bodyColor: tooltipOptions.bodyColor,
          borderColor: tooltipOptions.borderColor,
          borderWidth: tooltipOptions.borderWidth,
          displayColors: tooltipOptions.displayColors,
          caretPadding: tooltipOptions.caretPadding,
          padding: tooltipOptions.padding,
          callbacks: {
        //     title: function (tooltipItems) {
        //         let condition = tooltipItems[0].label;
        //         return data[condition].description;
        //     },
          label: function(context) {
            const labelIndex = context.dataIndex; // Get the index of the current item
            const labelKey = context.chart.data.labels[labelIndex]; // Get the key (code) of the current item
            const description = data[labelKey].description; // Get the description from your data
            return `${description}: ${context.parsed.x}`; // Return the custom tooltip text
          }
        },
        },
        zoom: {
          pan: {
            enabled: zoomOptions.panEnabled,
            mode: "x",
          },
          limits: {
            y: {
              min: 0,
              // max: 30000
            },
          },
          zoom: {
            wheel: {
              enabled: zoomOptions.zoomEnabled,
            },
            pinch: {
              enabled: zoomOptions.zoomEnabled,
            },
            mode: "x",
          },
        },
        datalabels: {
          color: 'white',
          display: function(context) {
              return context.dataset.data[context.dataIndex] !== null;  // Only display labels for non-null values
          },
          font: {
              weight: 'bold'
          },
          formatter: function(value, context) {
              let treatmentName = context.dataset.label; // This gets the treatment name
              if(value !== null) {
                  return treatmentName + ": " + value;
              } else {
                  return null;
              }
          }
      }
      },
    }),
    []//timeAxis]
  );

  return (
    <div className="card mb-4">
      <div className="stepGraph">
        <div className="d-flex card-header align-items-center justify-content-between">
          Treatments
        </div>
        <div className="card-body">
            <Bar type="horizontalBar" data={chartData} height={chartHeight} width={100} options={chartOptions}></Bar>
        </div>
        <div className="card-footer small text-muted">{lastUpdatedTimeNew}</div>
      </div>
    </div>
  );
}
