import React from "react";
import { useParams } from "react-router-dom";
import { BarChart } from "react-feather";

export default function PatientHeader(props) {
  let { subjectName, bg } = props;
  return (
    <header className={"page-header page-header-light pb-10" + (bg ? " " + bg : "")}>
      <div className="container-xl px-4">
        <div className="page-header-content pt-4">
          <div className="row align-items-center justify-content-between">
            <div className="col-auto mt-4">
              <h1 className="page-header-title">
                <BarChart className="page-header-icon" />
                {subjectName ? subjectName : ""}
              </h1>
              <div className="page-header-subtitle" />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
