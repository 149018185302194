import React from "react";
import { useNavigate } from "react-router-dom";
export default function SettingsNavbar(props) {
  let navigate = useNavigate();
  const onProfilePressed = async (event) => {
    event.preventDefault();
    navigate("/profile");
  };

  const onSecurityPressed = async (event) => {
    event.preventDefault();
    navigate("/security");
  };

  const onBillingPressed = async (event) => {
    event.preventDefault();
    navigate("/billing");
  };

  return (
    <nav className="nav nav-borders">
      <div className={"nav-link ms-0" + (props.activeTab.account ? " active" : "")} onClick={onProfilePressed}>
        Profile
      </div>
      {/* <div className={"nav-link" + (props.activeTab.billing ? " active" : "")} onClick={onBillingPressed}>
        Billing
      </div> */}
      <div className={"nav-link" + (props.activeTab.security ? " active" : "")} onClick={onSecurityPressed}>
        Security
      </div>
      {/* <a className="nav-link" href="account-notifications.html">
        Notifications
      </a> */}
    </nav>
  );
}
